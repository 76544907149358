<template>
  <v-container class="pa-4" fluid>
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="../assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto rounded-lg" max-width="374">
          <v-dialog v-model="loading" persistent>
            <v-card class="pa-4">
              <v-progress-circular indeterminate color="red"></v-progress-circular>
              <span class="ml-4" style="font-size: 14px">Please wait...</span>
            </v-card>
          </v-dialog>
          <!-- <v-responsive :aspect-ratio="16/9"> -->

          <template slot="progress">
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <v-card color="transparent" style="border-radius: 10px 10px 10px 10px;">
            <v-img class="rounded-t-lg " height="auto" :src="bookingDetails.hasOwnProperty('zone') &&
              bookingDetails.zone.image != '' &&
              bookingDetails.zone.image != null
              ? bookingDetails.zone.image
              : zoneImgURL
              " :lazy-src="bookingDetails.hasOwnProperty('zone') &&
                bookingDetails.zone.image != '' &&
                bookingDetails.zone.image != null
                ? bookingDetails.zone.image
                : zoneImgURL
                " alt="Parking.com garage">
              <v-row no-gutters>
                <v-col> <v-overlay absolute></v-overlay>
                </v-col>
                <v-col cols="12">
                  <div
                    :class="{ booking_header: isPreAuthEnabled == false, preauth_header: isPreAuthEnabled == true, 'white--text': true, 'text-left': true, 'mx-4': true }"
                    style="z-index: 100;">
                    <span style="z-index: 10;">
                      {{
                        bookingDetails.hasOwnProperty("booking")
                          ? bookingDetails.booking.header
                          : ""
                      }}
                    </span>
                  </div>
                  <div style="z-index: 100;" :class="{ booking_divider_div: isPreAuthEnabled == false }" v-if="
                    Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                    Object.hasOwnProperty.call(
                      bookingDetails.booking,
                      'TypeOfBooking'
                    ) &&
                    bookingDetails.booking.TypeOfBooking != 'Self reservation'
                  ">
                    <v-divider class="booking_divider mx-4" style="z-index: 100;"></v-divider>
                  </div>
                  <div
                    :class="{ booking_content: isPreAuthEnabled == false, preauth_content: isPreAuthEnabled == true, 'white--text': true, 'mx-2': true }"
                    style="z-index: 100;" v-if="
                      Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                      Object.hasOwnProperty.call(
                        bookingDetails.booking,
                        'TypeOfBooking'
                      ) &&
                      bookingDetails.booking.TypeOfBooking != 'Self reservation'
                    ">
                    Checked in at
                    {{
                      bookingDetails.hasOwnProperty("booking") ? booking_content : ""
                    }}
                    <span v-if="
                      bookingDetails.hasOwnProperty('booking') &&
                      bookingDetails.hasOwnProperty('zone') &&
                      bookingDetails.booking.vehicleNo != null &&
                      bookingDetails.booking.vehicleNo != ''
                    ">
                      with license plate
                      {{
                        bookingDetails.hasOwnProperty("booking")
                          ? bookingDetails.booking.vehicleNo
                          : ""
                      }}
                    </span>
                  </div>


                </v-col>
                <v-col cols="12" v-if="isPreAuthEnabled">
                  <pre-auth-sheet style="position: absolute;bottom:0;z-index: 100;padding: 2px;"></pre-auth-sheet>

                </v-col>
              </v-row>


              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>

            </v-img>
          </v-card>

          <v-card-title class="pa-1"></v-card-title>
          <v-fab-transition style="z-index: 100;">
            <v-btn v-show="Object.hasOwnProperty.call(bookingDetails, 'zone') &&
              bookingDetails.zone.isPedestrian == 1
              " class="fabIcon" color="#f2555c !important" fab dark raised right @click="getpedestrianAccess()"
              style="bottom: 30px; float: right; right: 25px;z-index: 100;top: -35px;">
              <v-img src="../assets/pedestrain_door.png" style="opacity: 1" width="32" height="32" contain></v-img>
              <!-- <v-icon class="material-icons-outlined">directions_walk</v-icon> -->
            </v-btn>
          </v-fab-transition>
          <v-card-text class="px-10  rounded-lg">
            <v-row align="center" class="mx-0 pb-0" no-gutters v-if="
              bookingDetails.hasOwnProperty('booking') &&
              bookingDetails.booking.hasOwnProperty('TypeOfBooking') &&
              bookingDetails.booking.TypeOfBooking != 'Self reservation' && bookingDetails.booking.TypeOfBooking != 'Host pass'
            ">
              <v-col cols="2">
                <v-icon style="font-size: 50px">mdi-clock-outline</v-icon>
              </v-col>
              <v-col cols="10" class="pl-1">
                <v-row no-gutters class="counter-content-position">
                  <v-col cols="12" class="pa-0">
                    <v-skeleton-loader v-show="!bookingDetails.hasOwnProperty('booking') ||
                      timeAgo == '' ||
                      timeAgo == null
                      " height="40px" max-width="200" type="heading"></v-skeleton-loader>
                    <span class="grey--text text--darken-1 time_ago" v-show="!(validatedDateTime > 0)">{{ timeAgo
                      }}</span>
                    <!-- <span
                          class="grey--text text--darken-1 time_ago"
                          v-show="gracePeriodTime > 0"
                          >{{ countDownGracePeriodTime }}</span
                        >-->
                    <span class="grey--text text--darken-1 time_ago" v-show="bookingDetails.hasOwnProperty('booking') &&
                      bookingDetails.booking.hasOwnProperty('validated') &&
                      bookingDetails.booking.validated == '1' &&
                      validatedDateTime > 0
                      ">{{ countDownValidatedTime }}</span>
                  </v-col>
                  <v-col cols="12" v-if="
                    bookingDetails.hasOwnProperty('booking') &&
                    validatedDateTime <= 0
                  ">
                    <span class="grey--text text--darken-1 time_ago_context">Since you parked</span>
                  </v-col>
                  <!-- <v-col cols="12" v-else-if="bookingDetails.hasOwnProperty('booking') && gracePeriodTime > 0">
                        <span class="grey--text text--darken-1 time_ago_context"
                          >Grace period remaining</span
                        >
                      </v-col>-->
                  <v-col cols="12" v-else-if="
                    bookingDetails.hasOwnProperty('booking') &&
                    bookingDetails.booking.hasOwnProperty('validated') &&
                    bookingDetails.booking.validated == '1' &&
                    validatedDateTime > 0
                  ">
                    <span class="grey--text text--darken-1 time_ago_context">Validation time remaining</span>
                  </v-col>
                  <v-col cols="12" v-show="bookingDetails.hasOwnProperty('booking') &&
                    bookingDetails.booking.hasOwnProperty('validated') &&
                    bookingDetails.booking.validated == '1' &&
                    bookingDetails.booking.hasOwnProperty('validationType') &&
                    (bookingDetails.booking.validationType == 'DIVRT' || bookingDetails.booking.validationType == 'Time Reduction')
                    ">
                    <span class="grey--text text--darken-1 validated_time">Validated until {{ validated_content
                      }}</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols class="align-self-center text-center" v-show="bookingDetails.hasOwnProperty('booking') &&
                bookingDetails.booking.hasOwnProperty('TypeOfBooking') &&
                bookingDetails.booking.TypeOfBooking == 'Monthly'
                ">
                <v-btn class="elevation-0 white--text" tile x-small color="green darken-2">Monthly</v-btn>
              </v-col>
              <v-col cols class="align-self-center text-center" v-show="bookingDetails.hasOwnProperty('booking') &&
                bookingDetails.booking.hasOwnProperty('TypeOfBooking') &&
                bookingDetails.booking.TypeOfBooking == 'Employee'
                ">
                <v-btn class="elevation-0 white--text" tile x-small color="green darken-2">Employee</v-btn>
              </v-col>
            </v-row>
            <v-row class="mx-0 pb-0 black--text pl-2" no-gutters v-if="
              bookingDetails.hasOwnProperty('booking') &&
              bookingDetails.booking.hasOwnProperty('TypeOfBooking') &&
              bookingDetails.booking.TypeOfBooking == 'Self reservation'
            ">
              <v-row no-gutters class="pb-4 text-center"><v-col>
                  <div>
                    Checked in at
                    {{
                      bookingDetails.hasOwnProperty("booking")
                        ? checkin_content
                        : ""
                    }}
                  </div>
                  <span v-if="
                    bookingDetails.hasOwnProperty('booking') &&
                    bookingDetails.hasOwnProperty('zone') &&
                    bookingDetails.booking.revEnd != null &&
                    bookingDetails.booking.revEnd != ''
                  ">
                    {{ checkout_content }}
                  </span>
                </v-col></v-row>
            </v-row>
            <v-container fluid pa-0 class="text-center" v-if="
              bookingDetails.hasOwnProperty('booking') &&
              bookingDetails.booking.hasOwnProperty('TypeOfBooking') &&
              (bookingDetails.booking.TypeOfBooking == 'Self reservation' || (bookingDetails.booking.TypeOfBooking == 'Host pass'))
            ">
              <v-row no-gutters v-if="checkValidValue(exitDateTime) && exitDateTime > 0">
                <v-col cols="12">
                  <v-row no-gutters class="counter-content-position">
                    <v-col cols="12" class="pa-0">
                      <v-icon class="material-icons-outlined " style="font-size: 50px">history_toggle_off</v-icon>
                      <v-skeleton-loader v-show="!bookingDetails.hasOwnProperty('booking')" height="40px"
                        max-width="200" type="heading"></v-skeleton-loader>
                      <span class="grey--text text--darken-1 time_ago" v-show="exitDateTime > 0">{{ countDownExitTime
                        }}</span>
                      <p class="grey--text text--darken-1 time_ago_context pl-15" style="margin-top: -15px"
                        v-if="exitDateTime > 0">
                        Remaining
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <v-row no-gutters align="center" class="my-1">
              <v-col cols="12" class="pa-0 align-self-center text-center">
                <v-btn class="elevation-0 white--text" tile x-small color="green darken-2" v-show="bookingDetails.hasOwnProperty('booking') &&
                  bookingDetails.booking.hasOwnProperty('validated') &&
                  bookingDetails.booking.validated == '1'
                  ">VALIDATED</v-btn>
                <!-- <v-icon color="red" class="ml-2" v-show="(bookingDetails.hasOwnProperty('booking') && bookingDetails.booking.validated == '1' && showPaymentCardAdded == '1')" >approval</v-icon> -->
              </v-col>
            </v-row>
            <v-row class="justify-center mb-1" no-gutters v-show="Object.hasOwnProperty.call(bookingDetails, 'zone') &&
              !(bookingDetails.zone.exitGate == '0')
              ">
              <span class="grey--text text--darken-1 font-weight-bold" v-if="
                showPaymentCardAdded &&
                (isCheckoutEnabled(1) ||
                  isCheckoutEnabled(2) ||
                  isCheckoutEnabled(3)) &&
                Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                Object.hasOwnProperty.call(
                  bookingDetails.booking,
                  'eventModeTxn'
                ) &&
                bookingDetails.booking.eventModeTxn == '0'
              ">Click an option below to exit</span>
            </v-row>
            <v-row class="justify-center my-1" no-gutters v-show="!(
              isCheckoutEnabled(1) ||
              isCheckoutEnabled(2) ||
              isCheckoutEnabled(3)
            )
              ">
              <span class="black--text text--darken-1 font-weight-bold text-h5">Checked In</span>
            </v-row>
          </v-card-text>
          <!-- <v-card-text class="text-center">Click an option to exit</v-card-text> -->
          <v-card-actions class="text-center justify-center px-0 pb-0 rounded-lg" v-if="
            Object.hasOwnProperty.call(bookingDetails, 'booking') &&
            !showPaymentCardAdded
          ">
            <v-container class="pa-0" fluid>
              <v-row no-gutters>
                <v-col cols="12" class="px-4 pb-2" v-if="
                  iosDevice &&
                  Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                  Object.hasOwnProperty.call(
                    bookingDetails.booking,
                    'paymentServices'
                  ) &&
                  Object.hasOwnProperty.call(
                    bookingDetails.booking.paymentServices,
                    'apple_pay'
                  ) &&
                  bookingDetails.booking.paymentServices.apple_pay == '1' && Object.hasOwnProperty.call(bookingDetails.booking, 'bid') && Object.hasOwnProperty.call(bookingDetails, 'user') && Object.hasOwnProperty.call(bookingDetails.user, 'contact') && Object.hasOwnProperty.call(bookingDetails.booking, 'startAt') && Object.hasOwnProperty.call(bookingDetails, 'zone') && Object.hasOwnProperty.call(bookingDetails.zone, 'locationCode')
                ">
                  <apple-pay width="100%" height="43px" :typeOfBooking="'CheckedIn'" :ios-device="iosDevice &&
                    Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                    Object.hasOwnProperty.call(
                      bookingDetails.booking,
                      'paymentServices'
                    ) &&
                    Object.hasOwnProperty.call(
                      bookingDetails.booking.paymentServices,
                      'apple_pay'
                    ) &&
                    bookingDetails.booking.paymentServices.apple_pay == '1' && Object.hasOwnProperty.call(bookingDetails.booking, 'bid') && Object.hasOwnProperty.call(bookingDetails, 'user') && Object.hasOwnProperty.call(bookingDetails.user, 'contact') && Object.hasOwnProperty.call(bookingDetails.booking, 'startAt') && Object.hasOwnProperty.call(bookingDetails, 'zone') && Object.hasOwnProperty.call(bookingDetails.zone, 'locationCode')
                    " :bookingId="bookingDetails.booking.bid" :contact="bookingDetails.user.contact"
                    :locationCode="bookingDetails.zone.locationCode"
                    :startAt="bookingDetails.booking.startAt"></apple-pay>
                </v-col>
                <v-col cols="12" class="px-4" v-if="
                  !iosDevice &&
                  Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                  Object.hasOwnProperty.call(
                    bookingDetails.booking,
                    'paymentServices'
                  ) &&
                  Object.hasOwnProperty.call(
                    bookingDetails.booking.paymentServices,
                    'google_pay'
                  ) &&
                  bookingDetails.booking.paymentServices.google_pay == '1' && Object.hasOwnProperty.call(bookingDetails.booking, 'bid') && Object.hasOwnProperty.call(bookingDetails, 'user') && Object.hasOwnProperty.call(bookingDetails.user, 'contact') && Object.hasOwnProperty.call(bookingDetails.booking, 'startAt') && Object.hasOwnProperty.call(bookingDetails, 'zone') && Object.hasOwnProperty.call(bookingDetails.zone, 'locationCode')
                ">
                  <google-pay width="100%" height="43px" class="mb-2" :bookingId="bookingDetails.booking.bid"
                    :contact="bookingDetails.user.contact" :locationCode="bookingDetails.zone.locationCode"
                    :startAt="bookingDetails.booking.startAt" :typeOfBooking="'CheckedIn'"></google-pay>
                </v-col>
                <v-col cols="12" class="px-4">
                  <v-btn style="font-weight: 700" elevation="20" rounded width="100%"
                    class="add_credit_card_btn white--text mb-2" @click="navigateToAddPayment">
                    Add Credit Card</v-btn>
                </v-col>
                <v-col cols="12" class="mt-4 text-center color-black px-4" style="font-size: 12px" v-show="Object.hasOwnProperty.call(bookingDetails, 'zone') &&
                  !(bookingDetails.zone.isGated == '0')
                  ">
                  <p>
                    You won't be able to exit the garage until your payment
                    method is on file. Please enter your payment method now to
                    avoid slow down at exit.
                  </p>
                </v-col>
                <v-col cols="12" class="mt-4 text-center color-black px-4" style="font-size: 12px" v-show="Object.hasOwnProperty.call(bookingDetails, 'zone') &&
                  !(bookingDetails.zone.isGated == '1') &&
                  validatedDateTime <= 0
                  ">
                  <p>
                    A valid credit card is required to park. Without one, you
                    could be subject to a parking violation and issued a
                    citation.
                  </p>
                </v-col>
                <v-col cols="12" class="mt-4 color-black text-center px-4" style="font-size: 10px" v-show="bookingDetails.hasOwnProperty('booking') &&
                  bookingDetails.booking.hasOwnProperty('validated') &&
                  bookingDetails.booking.validated == '1' &&
                  validatedDateTime > 0 &&
                  Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                  Object.hasOwnProperty.call(
                    bookingDetails.booking,
                    'eventModeTxn'
                  ) &&
                  bookingDetails.booking.eventModeTxn == '0'
                  ">
                  <p>
                    Exiting after the validated time period will require a valid
                    credit card to continue parking. Without one, you could be
                    subject to a parking violation and issued a citation.
                  </p>
                </v-col>

                <v-col cols="12">
                  <p class="text-center color-black" style="font-size: 9px">
                    By parking at this facility you are agree to Parking.com
                    <a style="color: #f2555c; text-decoration: none" href="https://parking.com/terms-and-conditions/"
                      target="_blank">
                      Terms of Use
                    </a>
                  </p>
                </v-col>
                <!-- <v-col cols="12" class="px-4 mt-4 color-black text-center" style="font-size: 12px"
                                    v-show="iosDevice">
                                    <p>
                                        You're only authorizing payment now. We'll charge you automatically when you
                                        checkout based on the
                                        duration of stay.
                                    </p>
                                </v-col> -->
                <v-col cols="12" class="mt-4 pb-2 text-center" v-if="
                  bookingDetails.zone.isPQREndReservation == 1 &&
                  (bookingDetails.booking.TypeOfBooking == 'Guest' ||
                    bookingDetails.booking.TypeOfBooking == 'Transient' ) && bookingDetails.booking.entryMode!='CC'
                ">
                  <v-btn text color="primary" style="
                      font-size: 13px;
                      font-weight: bolder;
                      text-decoration: underline;
                    " @click="hotelGuest">
                    <font-awesome-icon class="mr-1" icon="fa-solid fa-hotel"
                      style="font-size: 1.5rem; color: #1e3050" />
                    I am an overnight hotel guest</v-btn>
                </v-col>
                <v-col cols="12" class="mt-2 pa-0" v-if="
                  (bookingDetails.hasOwnProperty('booking') &&
                    bookingDetails.booking.hasOwnProperty('validated') &&
                    bookingDetails.booking.validated != '1' &&
                    bookingDetails.booking.isValidationEnabled == '1') ||
                  (Object.hasOwnProperty.call(bookingDetails, 'zone') &&
                    Object.hasOwnProperty.call(
                      bookingDetails.zone,
                      'isLoyaltySupported'
                    ) &&
                    bookingDetails.zone.isLoyaltySupported == '1' &&
                    bookingDetails.booking.validated != '1' &&
                    (bookingDetails.booking.TypeOfBooking == 'Guest' ||
                      bookingDetails.booking.TypeOfBooking == 'Transient' ||
                      bookingDetails.booking.TypeOfBooking ==
                      'Self reservation'))
                ">
                  <hr style="color: black !important" />

                  <v-card flat color="#ececec" class="ma-0 rounded-md pb-5" tile max-width="100%">
                    <v-card-text class="pb-0">
                      <v-row class="text-left pt-0" no-gutters>
                        <v-col cols="12" class="pa-0">
                          <p style="
                              font-size: 15px;
                              font-weight: bolder;
                            "
                            class="black--text"
                            >
                            Parking Validation
                          </p>
                        </v-col>

                        <v-col cols="12" class="text-center" v-if="
                          Object.hasOwnProperty.call(
                            bookingDetails,
                            'zone'
                          ) &&
                          Object.hasOwnProperty.call(
                            bookingDetails.zone,
                            'isLoyaltySupported'
                          ) &&
                          bookingDetails.zone.isLoyaltySupported == '1' &&
                          bookingDetails.booking.validated != '1' &&
                          (bookingDetails.booking.TypeOfBooking == 'Guest' ||
                            bookingDetails.booking.TypeOfBooking ==
                            'Transient' ||
                            bookingDetails.booking.TypeOfBooking ==
                            'Self reservation')
                        ">
                          <v-card style="border-radius: 15px" >
                            <v-card-text class="pa-0">
                              <v-img v-if="
                                Object.hasOwnProperty.call(
                                  bookingDetails,
                                  'zone'
                                ) &&
                                Object.hasOwnProperty.call(
                                  bookingDetails.zone,
                                  'loyaltyFlags'
                                ) &&
                                Object.hasOwnProperty.call(
                                  bookingDetails.zone.loyaltyFlags,
                                  'logo_path'
                                )
                              " :src="logoPath" class="pa-0" style="border-top-left-radius: 15px; border-top-right-radius: 15px" max-width="100%" height="79"></v-img>
                              <v-img v-else src="../assets/newGoldenNugget.jpg" class max-width="230"
                                height="79"></v-img>
                            </v-card-text>
                            <v-card-actions class="text-center">
                              <v-row no-gutters>
                                <v-col>
                                  <v-btn text class="pa-0 ma-0"
                                    style="text-transform: none !important;color: #f2555c !important;"
                                    @click="openRewardsDialog()">
                                    Add Card Details
                                  </v-btn>
                                </v-col>
                              </v-row>

                            </v-card-actions>
                          </v-card>
                          <v-row no-gutters class="my-3">
                          <v-col cols="5" class="align-self-center"><v-divider></v-divider></v-col>
                          <v-col cols="2" class="align-self-center">or</v-col>
                          <v-col cols="5" class="align-self-center"><v-divider></v-divider></v-col>
                        </v-row>
                        </v-col>
                      </v-row>
                      <v-col cols="12" class="" style="padding: 0;" v-show="bookingDetails.hasOwnProperty('booking') &&
                        bookingDetails.booking.hasOwnProperty('validated') &&
                        bookingDetails.booking.validated != '1' &&
                        bookingDetails.booking.isValidationEnabled == '1'
                        ">
                       

                        <v-btn rounded elevation="20" dense width="100%" height="40px" style="font-size: 15px"
                          class="validate_btn white--text blue darken-2 no-upper-case px-4 text-center"
                          @click="bookingDetails?.zone?.isDigitalValidationEnabledForCC == '1' ? openScanValidationCode() : openValidationDialog()">Apply
                          Your Validation</v-btn>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- for removal of rewards card -->
                <v-col cols="12" v-if="
                  Object.hasOwnProperty.call(
                    bookingDetails,
                    'zone'
                  ) &&
                  Object.hasOwnProperty.call(
                    bookingDetails.zone,
                    'isLoyaltySupported'
                  ) &&
                  bookingDetails.zone.isLoyaltySupported == '1' &&
                  Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'rewardCardDetails') && checkValidValue(bookingDetails.booking.rewardCardDetails) &&  bookingDetails.booking.validated == 1 && Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails , 'status') && bookingDetails.booking.rewardCardDetails.status!='REMOVED' 
                ">
                  <v-row no-gutters class="pa-2 pb-3" v-if=" Object.hasOwnProperty.call(bookingDetails, 'booking')  && Object.hasOwnProperty.call(bookingDetails.booking, 'rewardCardDetails') && Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails, 'isRentalCar') && bookingDetails.booking.rewardCardDetails.isRentalCar==1">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col class="text-left">
                          <span class="text-left pt-2 font-weight-bold pl-2">
                            Vehicle Details
                          </span>
                        </v-col>
                        <v-col class="text-right ">
                          <v-btn class="text-right align-self-center" text color="#f2555c" style="text-transform: none" @click="openUpdateVehicleDetails()">Edit</v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-card class="pa-0">
                        <v-card-text>
                          <v-row no-gutters class="pt-2 pb-2">
                            <v-col cols="6" class="text-left">
                              <span class="black--text">Plate</span>
                            </v-col>
                            <v-col cols="6" class="text-right">
                              <span class="black--text">{{ Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails, 'plateOriginal') && bookingDetails.booking.rewardCardDetails.plateOriginal ?   bookingDetails.booking.rewardCardDetails.plateOriginal : "-"}}</span>
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-row no-gutters class="pt-2">
                            <v-col cols="6" class="text-left">
                              <span class="black--text">Return Date</span>
                            </v-col>
                            <v-col cols="6" class="text-right">
                              <span class="black--text">{{ computedRentalReturnDate}}</span>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-card flat color="#ececec" class="ma-0 rounded-md pb-5" tile max-width="100%">
                    <v-card-text class="pb-0">
                      <v-row class="text-left pt-0" no-gutters>
                        <v-col cols="12" class="pa-0">
                          <p style="
                              font-size: 15px;
                              font-weight: bolder;
                            " class="black--text">
                            Parking Validation
                          </p>
                        </v-col>
                        <v-col cols="12">
                          <v-row no-gutters>
                            <v-col cols="8">
                              <p style="
                              font-size: 15px;
                              font-weight: bolder;
                              margin-bottom: 0% !important
                            ">
                                Your Saved Card
                              </p>
                            </v-col>
                            <v-col cols="4">

                              <v-btn text color="#f2555c" class="align-self-center pb-4" style="text-transform: none"
                                @click="confirmRewardsDialog = true">Remove ></v-btn>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12" class="text-center">
                          <v-card style="border-radius: 13px">
                            <v-card-text class="pa-0">
                              <v-img style="border-radius: 13px" v-if="
                                Object.hasOwnProperty.call(
                                  bookingDetails,
                                  'zone'
                                ) &&
                                Object.hasOwnProperty.call(
                                  bookingDetails.zone,
                                  'loyaltyFlags'
                                ) &&
                                Object.hasOwnProperty.call(
                                  bookingDetails.zone.loyaltyFlags,
                                  'logo_path'
                                )
                              " :src="logoPath" class="pa-0" max-width="100%" height="93">
                                <v-row no-gutters
                                  style="position: absolute;bottom: 0;background-color: rgb(0, 0, 0 , 0.6);width: 100%;">
                                  <v-col class="text-left white--text pl-2" cols="7" style="z-index:100 !important">

                                    {{ Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails, 'rewardCardNumber') ? bookingDetails.booking.rewardCardDetails.rewardCardNumber  : "-"}}
                                  </v-col>
                                  <v-col class="pr-3" cols="5">
                                    <v-chip v-if="bookingDetails.booking.rewardCardDetails.status!='ACTIVE'" x-small color="red" class="white--text"> <v-icon color="white"
                                        class="material-icons-outlined pr-1" x-small>error</v-icon> NOT
                                      APPLICABLE</v-chip>
                                  </v-col>
                                </v-row></v-img>
                              <v-img v-else src="../assets/newGoldenNugget.jpg" class max-width="230"
                                height="79"></v-img>
                            </v-card-text>
                          </v-card>

                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-4" v-if="
                bookingDetails.hasOwnProperty('zone') &&
                bookingDetails.zone.hasOwnProperty('mySpotSettings') &&
                bookingDetails.zone.mySpotSettings != null &&
                bookingDetails.zone.mySpotSettings.hasOwnProperty(
                  'parkingDisclosures'
                ) &&
                bookingDetails.zone.mySpotSettings.parkingDisclosures !=
                null &&
                bookingDetails.zone.mySpotSettings.parkingDisclosures != ''
              ">
                <v-col cols="12" class="pa-4 pt-0">
                  <div class="alert-msg-box">
                    <v-row no-gutters>
                      <v-col cols="12" class="text-start">
                        <p class="pa-0 ma-0" style="
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 501;
                          ">
                          ALERT MESSAGE
                        </p>
                      </v-col>
                    </v-row>
                    <p class="mt-2" v-html="bookingDetails.zone.mySpotSettings.parkingDisclosures
                      " style="
                        font-size: 12px;
                        text-align: justify;
                        letter-spacing: normal;
                        line-height: 1.3;
                      "></p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
          <v-card-actions class="justify-center px-0 pb-0" v-if="showPaymentCardAdded">
            <v-container class="pa-0" fluid>
              <v-row no-gutters class="text-center px-10" v-if="
                bookingDetails.hasOwnProperty('exitOptions') &&
                Object.hasOwnProperty.call(bookingDetails, 'zone') &&
                !(bookingDetails.zone.exitGate == '0') &&
                bookingDetails.exitOptions.length > 0 &&
                Object.hasOwnProperty.call(
                  bookingDetails.booking,
                  'eventModeTxn'
                ) &&
                bookingDetails.booking.eventModeTxn == '0'
              ">
                <v-col v-if="isCheckoutEnabled(1)">
                  <v-avatar color="red" class="pa-6" @click="openSMSDialog()">
                    <v-icon medium color="white accent-2" class="px-4" dark>
                      {{ getExitIcon(1) }}
                    </v-icon>
                  </v-avatar>
                  <p class="grey--text text--darken-1 exit-option-text">
                    {{ getExitText(1) }}
                  </p>
                </v-col>
                <v-col v-if="isCheckoutEnabled(2)">
                  <v-avatar color="red" class="pa-6" @click.stop="openSmsQrScanner()">
                    <v-icon medium color="white accent-2" class="px-4" dark>
                      {{ getExitIcon(2) }}
                    </v-icon>
                  </v-avatar>
                  <p class="grey--text text--darken-1 exit-option-text">
                    {{ getExitText(2) }}
                  </p>
                </v-col>
                <v-col v-if="isCheckoutEnabled(3)">
                  <v-avatar color="red" class="pa-6" @click="dialerDialog = true">
                    <v-icon medium color="white accent-2" class="px-4" dark>
                      {{ getExitIcon(3) }}
                    </v-icon>
                  </v-avatar>
                  <p class="grey--text text--darken-1 exit-option-text">
                    {{ getExitText(3) }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="
                bookingDetails.zone.isPQREndReservation == 1 &&
                bookingDetails.booking.TypeOfBooking == 'Self reservation'
              ">
                <v-col cols="12" class="px-2 mt-4">
                  <v-btn rounded elevation="20" class="add_licence_plate_btn white--text"
                    @click="openExtDialog()">Extend
                    Parking</v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters v-show="Object.hasOwnProperty.call(bookingDetails, 'zone') &&
                !(bookingDetails.zone.exitGate == '0') &&
                (isCheckoutEnabled(1) ||
                  isCheckoutEnabled(2) ||
                  isCheckoutEnabled(3)) &&
                Object.hasOwnProperty.call(
                  bookingDetails.booking,
                  'eventModeTxn'
                ) &&
                bookingDetails.booking.eventModeTxn == '0'
                ">
                <v-col cols="12" class="mt-4 pb-2 px-4 text-center color-black" style="font-size: 12px"
                  v-if="isCheckoutEnabled(2)">
                  <p class="mb-0">
                    When you're ready to leave, please look for the
                    <strong>QR Code</strong>
                    on the sign in the lane. Scan that code when prompted. Have a good day!
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters class="text-center pb-2 mt-1" v-show="(Object.hasOwnProperty.call(bookingDetails, 'zone') &&
                bookingDetails.zone.exitGate == '0' &&
                bookingDetails.booking.cardOnfile == '1' &&
                validatedDateTime <= 0) ||
                (Object.hasOwnProperty.call(
                  bookingDetails.booking,
                  'eventModeTxn'
                ) &&
                  bookingDetails.booking.eventModeTxn == '1')
                ">
                <v-col cols="12" class="mt-4 px-2 color-black px-4" style="font-size: 12px">
                  <p class="mb-0">
                    The card on file will be automatically charged for the time
                    parked when you exit the garage.
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="mt-4 text-center pb-2" v-show="bookingDetails.hasOwnProperty('booking') &&
                  bookingDetails.booking.hasOwnProperty('cardNo') &&
                  bookingDetails.booking.hasOwnProperty('accountType') &&
                  bookingDetails.booking.hasOwnProperty('cardUpdated') &&
                  !(bookingDetails.booking.cardUpdated == '1') &&
                  (bookingDetails.booking.accountType == '2' ||
                    (bookingDetails.booking.cardOnfile == '1' &&
                      bookingDetails.booking.accountType == '0')) &&
                  !(bookingDetails.booking.tapToChangeCard == '0') &&
                  !(
                    bookingDetails.booking.paymentType == 'APPLE_PAY' ||
                    bookingDetails.booking.paymentType == 'GOOGLE_PAY'
                  )
                  ">
                  <a class="text-caption" @click="navigateToAddPayment()">Tap to change card</a>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="mt-4 text-center color-black px-4" style="font-size: 12px" v-show="Object.hasOwnProperty.call(bookingDetails, 'zone') &&
                  !(bookingDetails.zone.exitGate == '1') &&
                  validatedDateTime <= 0 &&
                  bookingDetails.booking.cardOnfile != '1'
                  ">
                  <p>
                    A valid credit card is required to park. Without one, you
                    could be subject to a parking violation and issued a
                    citation.
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="mt-4 text-center color-black px-4 pb-2" style="font-size: 12px" v-show="bookingDetails.hasOwnProperty('booking') &&
                  bookingDetails.booking.hasOwnProperty('validated') &&
                  bookingDetails.booking.validated == '1' &&
                  validatedDateTime > 0 &&
                  Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                  Object.hasOwnProperty.call(
                    bookingDetails.booking,
                    'eventModeTxn'
                  ) &&
                  bookingDetails.booking.eventModeTxn == '0'
                  ">
                  <p>
                    Exiting after the validated time period will require a valid
                    credit card to continue parking. Without one, you could be
                    subject to a parking violation and issued a citation.
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-2" v-if="
                Object.hasOwnProperty.call(bookingDetails, 'booking') &&
                Object.hasOwnProperty.call(
                  bookingDetails.booking,
                  'paymentType'
                ) &&
                bookingDetails.booking.paymentType != '' &&
                (bookingDetails.booking.paymentType == 'APPLE_PAY' ||
                  bookingDetails.booking.paymentType == 'GOOGLE_PAY')
              ">
                <v-col cols="12" class="text-center font-weight-bold">
                  <span style="font-size: 13px">Authorized with
                    {{
                      getPaymentType(bookingDetails.booking.paymentType)
                    }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="px-4 mt-4 text-center color-black font-weight-bold" style="font-size: 13px"
                  v-show="(bookingDetails.hasOwnProperty('booking') &&
                    bookingDetails.booking.hasOwnProperty('cardNo') &&
                    bookingDetails.booking.hasOwnProperty('accountType') &&
                    bookingDetails.booking.accountType == '2') ||
                    (bookingDetails.booking.cardOnfile == '1' &&
                      (bookingDetails.booking.accountType == '0' ||
                        bookingDetails.booking.accountType == '1') &&
                      !(
                        bookingDetails.booking.cardNo == '0' ||
                        bookingDetails.booking.cardNo == '' ||
                        bookingDetails.booking.cardNo == null
                      ))
                    ">
                  <p class="mb-0 pb-3">
                    Card ending with *{{
                      bookingDetails.hasOwnProperty("booking") &&
                        bookingDetails.booking.hasOwnProperty("cardNo")
                        ? bookingDetails.booking.cardNo
                        : ""
                    }}
                    on file
                  </p>
                </v-col>
                <v-col cols="12">
                  <p class="text-center color-black" style="font-size: 9px">
                    By parking at this facility you are agree to Parking.com
                    <a style="color: #f2555c; text-decoration: none" href="https://parking.com/terms-and-conditions/"
                      target="_blank">
                      Terms of Use
                    </a>
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="
                bookingDetails.zone.isPQREndReservation == 1 &&
                (bookingDetails.booking.cardOnfile == '1' ||
                  bookingDetails.booking.cardOnfile == '2') &&
                (bookingDetails.booking.TypeOfBooking == 'Guest' ||
                  bookingDetails.booking.TypeOfBooking == 'Transient' ) && bookingDetails.booking.entryMode!='CC'
              ">
                <v-col cols="12" class="mt-3 pb-2 text-center">
                  <v-btn text color="primary" style="
                      font-size: 13px;
                      font-weight: bolder;
                      text-decoration: underline;
                    " @click="hotelGuest">
                    <font-awesome-icon class="mr-1" icon="fa-solid fa-hotel"
                      style="font-size: 1.5rem; color: #1e3050" />
                    I am an overnight hotel guest</v-btn>
                </v-col>
              </v-row>
              <v-col cols="12" class="mt-2 pa-0" v-if="
                (bookingDetails.hasOwnProperty('booking') &&
                  bookingDetails.booking.hasOwnProperty('validated') &&
                  bookingDetails.booking.validated != '1' &&
                  bookingDetails.booking.isValidationEnabled == '1') ||
                (Object.hasOwnProperty.call(bookingDetails, 'zone') &&
                  Object.hasOwnProperty.call(
                    bookingDetails.zone,
                    'isLoyaltySupported'
                  ) &&
                  bookingDetails.zone.isLoyaltySupported == '1' &&
                  bookingDetails.booking.validated != '1' &&
                  (bookingDetails.booking.TypeOfBooking == 'Guest' ||
                    bookingDetails.booking.TypeOfBooking == 'Transient' ||
                    bookingDetails.booking.TypeOfBooking ==
                    'Self reservation'))
              ">
                <hr style="color: black !important" />
                <v-card flat color="#ececec" class="ma-0 rounded-md pb-5" tile max-width="100%">
                    <v-card-text class="pb-0">
                      <v-row class="text-left pt-0" no-gutters>
                        <v-col cols="12" class="pa-0">
                          <p style="
                              font-size: 15px;
                              font-weight: bolder;
                            "
                            class="black--text"

                            >
                            Parking Validation
                          </p>
                        </v-col>

                        <v-col cols="12" class="text-center" v-if="
                          Object.hasOwnProperty.call(
                            bookingDetails,
                            'zone'
                          ) &&
                          Object.hasOwnProperty.call(
                            bookingDetails.zone,
                            'isLoyaltySupported'
                          ) &&
                          bookingDetails.zone.isLoyaltySupported == '1' &&
                          bookingDetails.booking.validated != '1' &&
                          (bookingDetails.booking.TypeOfBooking == 'Guest' ||
                            bookingDetails.booking.TypeOfBooking ==
                            'Transient' ||
                            bookingDetails.booking.TypeOfBooking ==
                            'Self reservation')
                        ">
                          <v-card style="border-radius: 15px">
                            <v-card-text class="pa-0">
                              <v-img v-if="
                                Object.hasOwnProperty.call(
                                  bookingDetails,
                                  'zone'
                                ) &&
                                Object.hasOwnProperty.call(
                                  bookingDetails.zone,
                                  'loyaltyFlags'
                                ) &&
                                Object.hasOwnProperty.call(
                                  bookingDetails.zone.loyaltyFlags,
                                  'logo_path'
                                )
                              " :src="logoPath" class="pa-0" style="border-top-left-radius: 25px; border-top-right-radius: 25px" max-width="100%" height="79"></v-img>
                              <v-img v-else src="../assets/newGoldenNugget.jpg" class max-width="230"
                                height="79"></v-img>
                            </v-card-text>
                            <v-card-actions class="text-center">
                              <v-row no-gutters>
                                <v-col>
                                  <v-btn text class="pa-0 ma-0"
                                    style="text-transform: none !important;color: #f2555c !important;"
                                    @click="openRewardsDialog()">
                                    Add Card Details
                                  </v-btn>
                                </v-col>
                              </v-row>

                            </v-card-actions>
                          </v-card>
                          <v-row no-gutters class="my-3">
                          <v-col cols="5" class="align-self-center"><v-divider></v-divider></v-col>
                          <v-col cols="2" class="align-self-center">or</v-col>
                          <v-col cols="5" class="align-self-center"><v-divider></v-divider></v-col>
                        </v-row>
                        </v-col>
                      </v-row>
                      <v-col cols="12" style="padding: 0;" class="mt-1  text-center" v-show="bookingDetails.hasOwnProperty('booking') &&
                        bookingDetails.booking.hasOwnProperty('validated') &&
                        bookingDetails.booking.validated != '1' &&
                        bookingDetails.booking.isValidationEnabled == '1'
                        ">
                       

                        <v-btn rounded elevation="20" dense width="100%" height="40px" style="font-size: 15px"
                          class="validate_btn white--text blue darken-2 no-upper-case px-4 text-center"
                          @click="bookingDetails?.zone?.isDigitalValidationEnabledForCC == '1' ? openScanValidationCode() : openValidationDialog()">Apply
                          Your Validation</v-btn>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- for removal of rewards card -->
                <v-col cols="12" v-if="
                  Object.hasOwnProperty.call(
                    bookingDetails,
                    'zone'
                  ) &&
                  Object.hasOwnProperty.call(
                    bookingDetails.zone,
                    'isLoyaltySupported'
                  ) &&
                  bookingDetails.zone.isLoyaltySupported == '1' &&
                  Object.hasOwnProperty.call(bookingDetails, 'booking') &&  Object.hasOwnProperty.call(bookingDetails.booking, 'validated') && bookingDetails.booking.validated == 1 && Object.hasOwnProperty.call(bookingDetails.booking, 'rewardCardDetails') && checkValidValue(bookingDetails.booking.rewardCardDetails) && bookingDetails.booking.rewardCardDetails.isSaveRewardCard == 1
                ">
                  <v-row no-gutters class="pa-2 pb-3" v-if=" Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'rewardCardDetails') && Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails, 'isRentalCar') && bookingDetails.booking.rewardCardDetails.isRentalCar==1">
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col class="text-left">
                          <span class="text-left pt-2 font-weight-bold pl-2">
                            Vehicle Details
                          </span>
                        </v-col>
                        <v-col class="text-right ">
                          <v-btn class="text-right align-self-center" text color="#f2555c" style="text-transform: none" @click="openUpdateVehicleDetails()">Edit</v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-card class="pa-0">
                        <v-card-text>
                          <v-row no-gutters class="pt-2 pb-2">
                            <v-col cols="6" class="text-left">
                              <span class="black--text">Plate</span>
                            </v-col>
                            <v-col cols="6" class="text-right">
                              <span class="black--text">{{ Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails, 'plateOriginal') && bookingDetails.booking.rewardCardDetails.plateOriginal ?   bookingDetails.booking.rewardCardDetails.plateOriginal : "-"}}</span>
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-row no-gutters class="pt-2">
                            <v-col cols="6" class="text-left">
                              <span class="black--text">Return Date</span>
                            </v-col>
                            <v-col cols="6" class="text-right">
                              <span class="black--text">{{ computedRentalReturnDate}}</span>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-card flat color="#ececec" class="ma-0 rounded-md pb-5" tile max-width="100%">
                    <v-card-text class="pb-0">
                      <v-row class="text-left pt-0" no-gutters>
                        <v-col cols="12" class="pa-0">
                          <p style="
                              font-size: 15px;
                              font-weight: bolder;
                            " 
                            class="black--text">
                            Parking Validation
                          </p>
                        </v-col>
                        <v-col cols="12">
                          <v-row no-gutters>
                            <v-col cols="8">
                              <p style="
                              font-size: 15px;
                              font-weight: bolder;
                              margin-bottom: 0% !important
                            ">
                                Your Saved Card
                              </p>
                            </v-col>
                            <v-col cols="4">

                              <v-btn text color="#f2555c" class="align-self-center pb-4" style="text-transform: none"
                                @click="confirmRewardsDialog = true">Remove ></v-btn>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12" class="text-center">
                          <v-card style="border-radius: 13px">
                            <v-card-text class="pa-0">
                              <v-img style="border-radius: 13px" v-if="
                                Object.hasOwnProperty.call(
                                  bookingDetails,
                                  'zone'
                                ) &&
                                Object.hasOwnProperty.call(
                                  bookingDetails.zone,
                                  'loyaltyFlags'
                                ) &&
                                Object.hasOwnProperty.call(
                                  bookingDetails.zone.loyaltyFlags,
                                  'logo_path'
                                )
                              " :src="logoPath" class="pa-0" max-width="100%" height="93">
                                <v-row no-gutters
                                  style="position: absolute;bottom: 0;background-color: rgb(0, 0, 0 , 0.6);width: 100%;">
                                  <v-col class="text-left white--text pl-2" cols="7" style="z-index:100 !important">

                                    {{ Object.hasOwnProperty.call(bookingDetails.booking.rewardCardDetails, 'rewardCardNumber') ? bookingDetails.booking.rewardCardDetails.rewardCardNumber  : "-" }}
                                  </v-col>
                                  <v-col class="pr-3" cols="5">
                                    <v-chip v-if="bookingDetails.booking.rewardCardDetails.status=='EXPIRED'" x-small color="red" class="white--text"> <v-icon color="white"
                                        class="material-icons-outlined pr-1" x-small>error</v-icon> NOT
                                      APPLICABLE</v-chip>
                                  </v-col>
                                </v-row></v-img>
                              <v-img v-else src="../assets/newGoldenNugget.jpg" class max-width="230"
                                height="79"></v-img>
                            </v-card-text>
                          </v-card>

                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              <v-col cols="12" class="mt-4 pt-0" v-if="
                bookingDetails.hasOwnProperty('zone') &&
                bookingDetails.zone.hasOwnProperty('mySpotSettings') &&
                bookingDetails.zone.mySpotSettings != null &&
                bookingDetails.zone.mySpotSettings.hasOwnProperty(
                  'parkingDisclosures'
                ) &&
                bookingDetails.zone.mySpotSettings.parkingDisclosures !=
                null &&
                bookingDetails.zone.mySpotSettings.parkingDisclosures != ''
              ">
                <div class="alert-msg-box">
                  <v-row no-gutters>
                    <v-col cols="12" class="text-start">
                      <p class="pa-0 ma-0" style="
                          font-size: 13px;
                          font-style: normal;
                          font-weight: 501;
                        ">
                        ALERT MESSAGE
                      </p>
                    </v-col>
                  </v-row>
                  <p class="mt-2" v-html="bookingDetails.zone.mySpotSettings.parkingDisclosures
                    " style="
                      font-size: 12px;
                      text-align: justify;
                      letter-spacing: normal;
                      line-height: 1.3;
                    "></p>
                </div>
              </v-col>
            </v-container>
          </v-card-actions>
          <!-- <v-row v-if="showPaymentCardAdded">
                <v-col>
                  <v-icon
                    x-small
                    color="green"
                    class="px-4"
                    dark
                    @click="navigateToCheckOut"
                  >mdi-exit-to-app</v-icon>
                </v-col>
              </v-row>-->
          <!-- </v-responsive> -->
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="updateRewardCardFlag" persistent>
      <v-card>
        <v-card-text>
          <v-row no-gutters class="text-center">
            <v-col cols="12" class="pt-4 ">
              <span class="font-weight-bold black--text">Update Vehicle Details </span>
            </v-col>
            <v-col cols="12" class="pt-2 px-10">
              <span>Please update the return date and time of your rental.</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1" class="text-left align-self-center py-5">
              <v-icon class="material-icons-outlined">calendar_month</v-icon>
            </v-col>
            <v-col cols="11" class="px-2">
              <v-text-field v-model="computedRewardsDateFormatted" @click="() => {
                openRewardsDatePicker = true;
              }
                " hide-details="auto" readonly></v-text-field>
              <v-dialog v-model="openRewardsDatePicker" max-width="450px">
                <v-card style="background-color: #f2555c; color: white"><v-card-title>
                    <v-row no-gutters>
                      <v-col class="text-left" cols="12">
                        <span style="font-size: 15px; color: #fabbbe">{{
                          rewardsTime !== null && rewardsTime !== "" ? formattedRewardsYear : ""
                        }}</span>
                      </v-col>
                      <v-col>
                        <span style="font-size: 30px">
                          {{
                            rewardsTime !== null && rewardsTime !== ""
                              ? formattedRewardsDateTime
                              : ""
                          }}</span>
                      </v-col>
                    </v-row>
                  </v-card-title></v-card>
                <Calendar inline class="text-left" value :stepMinute="15" hourFormat="12" :minDate="minDate"
                  v-model="rewardsTime" @date-select="rewardsTimeChange()" />
              </v-dialog>
            </v-col>
          </v-row>

          <v-row no-gutters >
            <v-col cols="1" class="text-right align-self-center">
              <v-icon class="material-icons-outlined">schedule</v-icon>
            </v-col>
            <v-col cols="11" class="px-3">
              <v-row>
                <v-col>
                  <v-text-field v-model="formattedRewardsTime" @click="() => {
                    openRewardsTimePicker = true;
                    round(rewardsTime);
                  }
                    " readonly></v-text-field>
                  <v-dialog v-model="openRewardsTimePicker" max-width="320px" class="pa-0" @click:outside="() => {
                    setRewardsDefaultDate();
                  }
                    ">
                    <v-card><v-card-title style="background-color: #f2555c; color: white">Return time of your rental
                        car</v-card-title>
                      <Calendar inline show-time time-only class="text-left" value :stepMinute="15"
                        selection-mode="single" hourFormat="12" :minDate="minDate" v-model="rewardsTime" />
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12">
                  <v-btn  rounded block elevation="20"  class="white--text exit_dialog_btn" @click="updateRewardCard()" style="text-transform: none">Update</v-btn>
                </v-col>
                <v-col cols="12" class="mt-2 text-center pt-5">
                  <p style="cursor: pointer" @click="updateRewardCardFlag = false">
                    Close
                  </p>
                </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmRewardsDialog">
      <v-card>
        <v-card-title>
          Remove Saved Reward Card
        </v-card-title>
        <v-card-text>
          This action will remove the validation associated with your reward card for this transaction.
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col class="text-right">
              <v-btn text style="text-transform: none" @click="confirmRewardsDialog = false">Close</v-btn>

              <v-btn text style="text-transform: none;" color="#f2555c" @click="removeSavedRewardCard()">
                Remove</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog v-model="removeRewardCardDialog" persistent max-width="290">
      <v-card class="pa-5">
        <v-row no-gutters class="text-center">
          <v-col cols="12" class="text-center pt-5">
            <img src="../assets//remove-rewardcard.svg" width="100px" height="100px" />
          </v-col>
          <v-col cols="12">
            <span>{{ rewardCardMsg }}</span>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn pt-4"
                  @click="removeRewardCardDialog = false">OK</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="green accent-2">{{ alertIcon }}</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ alertMsg }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                  @click="closeAlertDialog()">Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="successDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-img
          alt="Success"
          contain
          height="100"
          src="../assets/success_check.gif"
        />
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ alertMsg }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                  @click="successDialog = false">Ok</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loyalty" width="750px" persistent>
      <v-card>
        <v-row no-gutters class="text-center">
          <v-col cols="12" class="text-center pt-5">
            <img src="../assets//claim-parking.svg" width="200px" height="65px" />
          </v-col>
          <v-col cols="12" class="text-center pt-3">
            <span class="font-weight-bold px-2" style="font-size: 23px;">Claim Your Parking Validation</span>
          </v-col>
        </v-row>

        <v-card-text>
          <v-row no-gutters class="mt-4 text-center px-5">
            <v-col>
              <p style="font-size: large; color: black">{{ loyaltyText }}</p>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col>
              <v-text-field :label="loyaltyLabelText
                " outlined v-model="loyalty24knum" hide-details="auto" @keyup="loyaltyValid"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1">
              <v-checkbox hide-details="auto" v-model="saveRewardCardFlag" @click="rewardCardChange" ></v-checkbox>
            </v-col>
            <v-col cols="11" class="text-left align-self-center pt-5 pl-3 "
              style="font-weight: 500 !important;font-size: 18px !important">
              Save reward card
            </v-col>
          </v-row>
          <v-row no-gutters v-if="saveRewardCardFlag">
            <v-col cols="1">
              <v-checkbox hide-details="auto" v-model="rentalCarFlag"></v-checkbox>
            </v-col>
            <v-col cols="11" class="text-left align-self-center pt-5 pl-3"
              style="font-weight: 500 !important;font-size: 18px !important">
              I'm driving a rental car
            </v-col>
          </v-row>
          <v-row no-gutters v-if="rentalCarFlag">
            <v-col cols="12" class="pt-2 px-10 ">
              <span>Please enter the return date and time of your rental.</span>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="rentalCarFlag">
            <v-col cols="1" class="text-left align-self-center py-5">
              <v-icon class="material-icons-outlined">today</v-icon>
            </v-col>
            <v-col cols="11" class="px-2">
              <v-text-field v-model="computedRewardsDateFormatted" @click="() => {
                openRewardsDatePicker = true;
              }
                " hide-details="auto" readonly></v-text-field>
              <v-dialog v-model="openRewardsDatePicker" max-width="450px">
                <v-card style="background-color: #f2555c; color: white"><v-card-title>
                    <v-row no-gutters>
                      <v-col class="text-left" cols="12">
                        <span style="font-size: 15px; color: #fabbbe">{{
                          rewardsTime !== null && rewardsTime !== "" ? formattedRewardsYear : ""
                        }}</span>
                      </v-col>
                      <v-col>
                        <span style="font-size: 30px">
                          {{
                            rewardsTime !== null && rewardsTime !== ""
                              ? formattedRewardsDateTime
                              : ""
                          }}</span>
                      </v-col>
                    </v-row>
                  </v-card-title></v-card>
                <Calendar inline class="text-left" value :stepMinute="15" hourFormat="12" :minDate="minDate"
                  v-model="rewardsTime" @date-select="rewardsTimeChange()" />
              </v-dialog>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="
            rentalCarFlag
          ">
            <v-col cols="1" class="text-right align-self-center">
              <v-icon class="material-icons-outlined">schedule</v-icon>
            </v-col>
            <v-col cols="11" class="px-3">
              <v-row>
                <v-col>
                  <v-text-field v-model="formattedRewardsTime" @click="() => {
                    openRewardsTimePicker = true;
                    round(rewardsTime);
                  }
                    " readonly></v-text-field>
                  <v-dialog v-model="openRewardsTimePicker" max-width="320px" class="pa-0" @click:outside="() => {
                    setRewardsDefaultDate();
                  }
                    ">
                    <v-card><v-card-title style="background-color: #f2555c; color: white">Return time of your rental
                        car</v-card-title>
                      <Calendar inline show-time time-only class="text-left" value :stepMinute="15"
                        selection-mode="single" hourFormat="12" :minDate="minDate" v-model="rewardsTime" />
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters class="pt-6 text-center">
            <v-col cols="12">
              <v-btn color="#F2555C" width="100%" rounded class="white--text" :disabled="disableLoyalty"
                :loading="validateBookingPending" @click="submitLoyalty(loyalty24knum)">{{saveRewardCardFlag ? 'Save and' : ''}} Claim</v-btn>
            </v-col>
            <v-col cols="12" class="mt-1">
              <v-btn style="text-transform: none !important;" text @click="loyalty = false">Close</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="qrCodeDialog" width="fit-content" persistent>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon @click="closeQRCodeDialog()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-1">
          <qr-code-scanner :scan="'sms'" v-bind:qrbox="250" v-bind:fps="30"
            :cameraStatus="cameraStatus"></qr-code-scanner>
          <v-progress-circular v-if="!cameraStatus" class="validation-pending" indeterminate
            color="red"></v-progress-circular>
        </v-card-text>
        <v-card-actions>
          <v-container class="pa-0" fluid>
            <v-row no-gutters>
              <v-col cols="7">
                <p class="grey--text font-weight-bold qr_text mr-4">
                  Scan the QR code affixed to exit the gate
                </p>
              </v-col>
              <v-spacer></v-spacer>
              <v-img src="../assets/qr-code.png" style="opacity: 0.7" max-width="52"></v-img>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog v-model="smsDialog" width="fit-content">
        <v-card class="pa-0" ref="smscontainer">
          <v-card-title class="justify-center" style="position: relative">
            <v-btn class elevation="0" color="white" dark fab x-large>
              <v-icon x-large color="red accent-2">mdi-message-text</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container class="px-4" fluid>
              <v-row no-gutters>
                <v-col cols="12" class="text-left">
                  <p class="color-black" style="font-size: 24px; line-height: 35px">
                    Enter the gate code shown in the lane to exit.
                  </p>
                  <!-- <p class="sms-caption color-black">
                        We'll copy the code into a text message for you. You'll need
                        to tap send on the message to open the gate.
                      </p>-->
                </v-col>
                <v-col cols="12" class="mt-2">
                  <v-text-field class="sms-input" color="red" v-model="smsText" outlined label="Gate code" maxlength="6"
                    type="tel" autofocus v-on:keyup.enter="openSMSApp()" @focus="scrollToSubmit()"
                    @mouseup="scrollToSubmit()" @keydown="scrollToSubmit()" clearable height="80px"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-progress-circular v-if="smsPending" class="validation-pending" indeterminate
              color="red"></v-progress-circular>
          </v-card-text>
          <v-card-actions class="text-center">
            <v-container pa-0 fluid>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-btn id="submitsmsbtn" rounded block elevation="20" :disabled="smsText == '' || smsPending"
                    class="white--text exit_dialog_btn" @click="openSMSApp()">SUBMIT</v-btn>
                </v-col>
                <v-col cols="12" class="mt-2">
                  <p style="cursor: pointer" @click="smsDialog = false">
                    Close
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog v-model="validateQRCodeDialog" width="fit-content" persistent>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-icon
              @click="bookingDetails.zone.isDigitalValidationEnabledForCC == '1' ? validateQRCodeDialog = false : closeValidationQRDialog()">mdi-close</v-icon>
          </v-card-title>
          <v-card-text class="pa-1">
            <validation-qr-code-scanner :scan="'validationCode'" v-bind:qrbox="250" v-bind:fps="30"
              :cameraStatus="validationCameraStatus"></validation-qr-code-scanner>
            <v-progress-circular v-if="!validationCameraStatus" class="validation-pending" indeterminate
              color="red"></v-progress-circular>
          </v-card-text>
          <v-card-actions>
            <v-container class="pa-0" fluid>
              <v-row no-gutters>
                <v-col cols="7">
                  <p class="grey--text font-weight-bold qr_text mr-4">
                    Please scan the validation QR code
                  </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-img src="../assets/qr-code.png" style="opacity: 0.7" max-width="52"></v-img>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog v-model="validateBookingDialog" width="fit-content">
        <v-card class="pa-0" ref="validatecontainer">
          <v-card-title class="justify-center" style="position: relative">
            <v-btn class elevation="0" color="white" dark fab large>
              <v-icon x-large color="red accent-2">mdi-ticket-percent</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container class="px-4 py-0" fluid>
              <v-row no-gutters>
                <v-col cols="12" class="text-left">
                  <p class="color-black text-center" style="font-size: 24px; line-height: 35px">
                    If the Merchant provided you a validation code, you may scan
                    it or enter it here.
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-btn rounded block elevation="20" class="white--text exit_dialog_btn"
                    @click="openScanValidationCode()">Scan Validation Code</v-btn>
                </v-col>
                <v-col cols="12" class="text-left mt-4">
                  <p class="color-black" style="font-size: 24px; line-height: 35px">
                    or enter it below
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-text-field class="sms-input" color="red" v-model="validateBookingTxt" outlined
                    label="Validation code" hide-details="auto" v-on:keyup.enter="validateBooking()"
                    @focus="scrollToValidateBtn()" @mouseup="scrollToValidateBtn()" @keydown="scrollToValidateBtn()"
                    clearable height="80px"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-progress-circular v-if="validateBookingPending" class="validation-pending" indeterminate
              color="red"></v-progress-circular>
          </v-card-text>
          <v-card-actions class="text-center">
            <v-container pa-0 fluid>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-btn id="validatebookingbtn" rounded block elevation="20" :disabled="validateBookingTxt == '' ||
                    validateBookingTxt == null ||
                    validateBookingPending
                    " class="white--text exit_dialog_btn" @click="onValidateText()">SUBMIT</v-btn>
                </v-col>
                <v-col cols="12" class="mt-2">
                  <p style="cursor: pointer" @click="validateBookingDialog = false">
                    Close
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog v-model="dialerDialog" width="fit-content">
        <v-card class="pa-0">
          <v-card-title class="justify-center" style="position: relative">
            <v-btn class elevation="0" color="white" dark fab x-large>
              <v-icon x-large color="red accent-2">mdi-phone-in-talk</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container class="px-4" fluid>
              <v-row no-gutters>
                <v-col cols="12" class="text-left">
                  <p class="color-black" style="font-size: 24px; line-height: 35px">
                    Tap to call the gate. When prompted, enter the gate code
                    shown in the lane to exit.
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="text-center">
            <v-container pa-0 fluid>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-btn rounded block elevation="20" class="white--text exit_dialog_btn" @click="openDialerApp">CALL
                    GATE</v-btn>
                </v-col>
                <v-col cols="12" class="mt-2">
                  <p style="cursor: pointer" @click="dialerDialog = false">
                    Close
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog v-model="extensionDialog">
      <v-card class="pa-0">
        <v-card-title></v-card-title>
        <v-card-text class="pa-0">
          <v-row class="text-center mt-3" no-gutters>
            <v-col>
              <p class="black--text" style="font-weight: bold">
                Choose Date
                <span v-if="
                  Object.hasOwnProperty.call(bookingDetails, 'zone') &&
                  Object.hasOwnProperty.call(
                    bookingDetails.zone,
                    'timePickerConfiguration'
                  ) &&
                  this.bookingDetails.zone.timePickerConfiguration == '1' &&
                  bookingDetails.zone.isPQREndReservation == 1
                ">and Time</span>
              </p>
              <p>Expires at {{ countDownExitTime }}</p>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1" class="text-right align-self-center py-5">
              <v-icon class="material-icons-outlined">calendar_month</v-icon>
            </v-col>
            <v-col cols="11" class="px-2">
              <v-text-field v-model="computedDateFormatted" @click="() => {
                openDatePicker = true;
              }
                " hide-details="auto" readonly></v-text-field>
                 <p class="black--text text-left pb-4" v-if="Object.hasOwnProperty.call(bookingDetails, 'zone') &&
                  Object.hasOwnProperty.call(
                    bookingDetails.zone,
                    'timePickerConfiguration'
                  ) &&
                  this.bookingDetails.zone.timePickerConfiguration == '0'"
                              style="padding-bottom: 0 !important;margin-bottom: 0 !important  ;">
                              {{ zoneEndTimeLabel }}</p>
              <v-dialog v-model="openDatePicker" max-width="450px">
                <v-card style="background-color: #f2555c; color: white"><v-card-title>
                    <v-row no-gutters>
                      <v-col class="text-left" cols="12">
                        <span style="font-size: 15px; color: #fabbbe">{{
                          time !== null && time !== "" ? formattedRewardsYear : ""
                        }}</span>
                      </v-col>
                      <v-col>
                        <span style="font-size: 30px">
                          {{
                            time !== null && time !== ""
                              ? formattedRewardsDateTime
                              : ""
                          }}</span>
                      </v-col>
                    </v-row>
                  </v-card-title></v-card>
                <Calendar inline class="text-left" value :stepMinute="15" hourFormat="12" :minDate="minDate"
                  @date-select="onTimePickerChange" :maxDate="maxDate" v-model="time" />
              </v-dialog>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="
            Object.hasOwnProperty.call(bookingDetails, 'zone') &&
            Object.hasOwnProperty.call(
              bookingDetails.zone,
              'timePickerConfiguration'
            ) &&
            bookingDetails.zone.timePickerConfiguration == '1'
          ">
            <v-col cols="1" class="text-right align-self-center">
              <v-icon class="material-icons-outlined">schedule</v-icon>
            </v-col>
            <v-col cols="11" class="px-3">
              <v-row>
                <v-col>
                  <v-text-field v-model="formattedTime" @click="() => {
                    openTimePicker = true;
                    round(time);
                  }
                    " readonly></v-text-field>
                  <v-dialog v-model="openTimePicker" max-width="320px" class="pa-0" @click:outside="() => {
                    setDefaultDate();
                    onTimePickerChange();
                  }
                    ">
                    <v-card><v-card-title style="background-color: #f2555c; color: white">What time will you
                        exit?</v-card-title>
                      <Calendar inline show-time time-only class="text-left" value :stepMinute="15"
                        selection-mode="single" hourFormat="12" :minDate="minDate" :maxDate="maxDate" v-model="time" />
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-container fluid pa-0>
            <Estimate :singleQuotes="singleQuotes" :doubleQuotes="doubleQuotes" :estimate="estimate"
              :formattedEntryDisplayTime="formattedEntryDisplayTime" :beforeEstimate="beforeEstimate" :state="'20px'">
            </Estimate>
            <v-row no-gutters class="mt-3 pa-4">
              <v-col>
                <v-btn rounded elevation="20" :loading="extendBtnLoading" class="add_licence_plate_btn white--text"
                  @click="extendEDT()">Extend Parking</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="invalidQRDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ invalidQRMsg }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="20" class="white--text exit_dialog_btn" @click="reScanQR()">Re-Scan
                  Code</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="invalidValidationQRDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ invalidValidationQRMsg }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="20" class="white--text exit_dialog_btn"
                  @click="reScanValidationQR()">Re-Scan Code</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="invalidSMSDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ invalidSMSMsg }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="20" class="white--text exit_dialog_btn"
                  @click="closeInValidSmsDialog()">Re-Enter Code</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="pedestrianAccessDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ pedestrianAccessText }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded elevation="0" class="white--text exit_dialog_btn"
                  @click="pedestrianAccessDialog = false">Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="applyValidationDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title style="
            font-size: 15px;
            white-space: normal;
            line-height: 1.5;
            word-break: keep-all;
            font-weight: 500;
          ">
          <v-row no-gutters>
            <v-col cols="11">
              <p class="mb-0">
                Tap Apply to redeem parking transfer until
                {{ couponforfreeParkValidTill }}.
              </p>
            </v-col>
            <v-col cols="1">
              <v-icon class="material-icons-outlined" @click="applyValidationDialog = false">close</v-icon>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <p class="mb-0">
            Because you parked at {{ freeParkingBy }}, you can transfer your
            parking.
          </p>
        </v-card-text>
        <v-card-actions class="pb-6">
          <div class="pa-0" flat style="
              border: 1px solid black;
              height: 40px;
              border-style: dotted;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #e0e0e0;
              border-radius: 10px 10px 10px 10px;
            ">
            <p class="pa-3 ma-0">{{ freeParkingCouponCode }}</p>
          </div>
          <v-spacer></v-spacer>
          <v-btn class="white--text apply_dialog_btn" @click="applyValidation()">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <out-standing-payments-view :outStandingPayments="outStandingPayments"
      :iosDevice="iosDevice"></out-standing-payments-view>
  </v-container>
</template>

<script>
import Calendar from "../uiComponents/Calendar/Calendar";
import PreAuthSheet from "@/uiComponents/PreAuthSheet.vue";
import OutStandingPaymentsView from "@/components/OutStandingPaymentsView.vue";
import Estimate from "../components/Estimate.vue";
import APIHelper from "../apiHelper";
import bookingsvcAPIHelper from "../bookingsvcAPIHelper";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css";
import GooglePay from "./GooglePay.vue";
// import loggerHelper from "../loggerHelper";
import { mapGetters } from "vuex";
// import { QrcodeStream } from "vue-qrcode-reader";
import QrCodeScanner from "@/views/QrCodeScanner.vue";
import ValidationQrCodeScanner from "@/views/ValidationQrCodeScanner.vue";
import ApplePay from "@/views/ApplePay.vue";
import { formatDateTime } from "@/utils/formatDateTime";
import { EventBus } from "@/lib/EventBus";
import { secondsToHms } from "@/utils/formatDateTime";
import { addMinutes, addDays, addHours } from "date-fns";
import { format, isValid } from "date-fns";
import { dateToTimeZoneDate , format24TimeToAMPM} from "@/utils/formatDateTime";

// import QrScanner from 'qr-scanner';
export default {
  name: "CheckIn",
  components: {
    OutStandingPaymentsView,
    QrCodeScanner,
    ValidationQrCodeScanner,
    ApplePay,
    GooglePay,
    Calendar,
    PreAuthSheet,
    Estimate,
  },
  data: () => ({
    // rules: {
    //   counter: value => value.length <= 6 || "Max 6 characters"
    // },
    successDialog: false,
    zoneEndTimeLabel: "",
    updateRewardCardFlag: false,
    confirmRewardsDialog: false,
    openRewardsTimePicker: false,
    removeRewardCardDialog: false,
    claimRewardBtnLoading: false,
    openRewardsDatePicker: false,
    rewardCardMsg: "",
    saveRewardCardFlag: true,
    rentalCarFlag: false,
    time: "",
    rewardsTime: "",
    outStandingPayments: false,
    openTimePicker: false,
    openDatePicker: false,
    minTimeDate: null,
    maxTimeDate: null,
    beforeEstimate: null,
    currentDate: format(new Date(), "yyyy-MM-dd"),
    loyalty24knum: "",
    alertIcon: "mdi-check-circle",
    alertDialog: false,
    alertMsg: "",
    loyalty: false,
    extDateAndTime: null,
    extendBtnLoading: false,
    loading: false,
    extensionDialog: false,
    stopTime: "",
    exitTimeSelected: {},
    timePickerHrRange: null,
    timeExceeded: false,
    timePickerMinRange: null,
    minDate: null,
    maxDate: null,
    Timemenu: false,
    startDate: "",
    maxDateTime: null,
    estimate: null,
    Datemenu: false,
    exitDate: null,
    dateFormatted: null,
    exitDateTime: 0,
    exitTimeInterval: null,
    iosDevice: false,
    invalidValidationQRDialog: false,
    cameraStatus: false,
    validationCameraStatus: false,
    validatedDateTime: 0,
    gracePeriodTime: 0,
    validatedInterval: "",
    duration: 300,
    offset: 0,
    pedestrianAccessDialog: false,
    pedestrianAccessText: "",
    easing: "easeInOutCubic",
    pageHeight: window.outerHeight,
    qrCodeDialog: false,
    countUpTime: "",
    interval: "",
    isValid: undefined,
    camera: "auto",
    smsText: "",
    smsDialog: false,
    dialerDialog: false,
    invalidQRMsg: "",
    invalidValidationQRMsg: "",
    invalidQRDialog: false,
    invalidSMSMsg: "",

    invalidSMSDialog: false,
    smsPending: false,
    validateBookingPending: false,
    validateBookingDialog: false,
    validateBookingTxt: "",
    validateQRCodePending: false,
    validateQRCodeDialog: false,
    applyValidationDialog: false,
    freeParkingBy: "",
    freeParkingCouponCode: "",
    mappedlocationForFreePark: [],
    couponforfreeParkValidTill: null,
  }),
  computed: {
    ...mapGetters({
      bookingId: "getterBookingId",
      bookingDetails: "getterBookingDetails",
      zoneDetails: "getterZoneDetails",
      entryTime: "getterEntryTime",
      exitGateCodes: "getterExitGateCodes",
      isPQREndReservation: "getterZoneIsPQREndReservation",
    }),
    customEndTimeMinDate() {
            let currentTime = this.bookingDetails?.zone?.timezone
                ?
                dateToTimeZoneDate(new Date(),
                    this.bookingDetails?.zone?.timezone
                )
                : new Date();
            let givenTime = this.time
            let timeVal = this.bookingDetails?.zone?.customizedEndTime != null && this.bookingDetails?.zone?.customizedEndTime != undefined ? this.bookingDetails?.zone?.customizedEndTime.split(':') : ['23', '59', '59']
            givenTime.setHours(timeVal[0], timeVal[1], timeVal[2])
            let check = false;
            if (givenTime <= currentTime) {
                check = true;
                return check
            }
            else {
                check = false
                return check
            }
        },
    computedRentalReturnDate(){
      var dateString = "-"
      if(Object.hasOwnProperty.call(this.bookingDetails, 'booking') && Object.hasOwnProperty.call(this.bookingDetails.booking , 'rewardCardDetails') && Object.hasOwnProperty.call(this.bookingDetails.booking.rewardCardDetails, 'rentalCarValidTill')){
      let datevalue = this.bookingDetails.booking.rewardCardDetails.rentalCarValidTill
        dateString = datevalue.toString().split("T")[0] +" "+ datevalue.toString().split("T")[1].split(".")[0]
      }
      return this.checkValidValue(dateString) && dateString !="-" ?   format(new Date(dateString) , "dd MMM hh:mm aaa") : "-"
    },
    isPreAuthEnabled() {
      return Object.hasOwnProperty.call(this.bookingDetails, 'zone') && Object.hasOwnProperty.call(this.bookingDetails?.zone, 'preAuth') && Object.hasOwnProperty.call(this.bookingDetails?.zone?.preAuth, 'enabled') && this.bookingDetails?.zone?.preAuth?.enabled == '1' && (this.bookingDetails?.booking?.TypeOfBooking == 'Guest' || this.bookingDetails?.booking?.TypeOfBooking == 'Transient' || this.bookingDetails?.booking?.TypeOfBooking == 'Self reservation') && this.bookingDetails?.booking?.eventModeTxn == '0' && this.bookingDetails?.booking?.entryMode != 'CC'
    },
    loyaltyText() {
      return this.bookingDetails?.zone?.loyaltyFlags?.dialog_text !== null &&
        this.bookingDetails?.zone?.loyaltyFlags?.dialog_text !== undefined &&
        this.bookingDetails?.zone?.loyaltyFlags?.dialog_text !== ""
        ? this.bookingDetails?.zone?.loyaltyFlags?.dialog_text
        : "Enter your Loyalty Card Number";
    },
    loyaltyLabelText() {
      return this.bookingDetails?.zone?.loyaltyFlags?.label_text !== null &&
        this.bookingDetails?.zone?.loyaltyFlags?.label_text !== undefined &&
        this.bookingDetails?.zone?.loyaltyFlags?.label_text !== ""
        ? this.bookingDetails?.zone?.loyaltyFlags?.label_text
        : "Enter your Loyalty Card Number";
    },
    logoPath() {
      return this.bookingDetails?.zone?.loyaltyFlags?.logo_path !== null &&
        this.bookingDetails?.zone?.loyaltyFlags?.logo_path !== undefined &&
        this.bookingDetails?.zone?.loyaltyFlags?.logo_path != ""
        ? this.bookingDetails?.zone?.loyaltyFlags?.logo_path
        : "../assets/newGoldenNugget.jpg";
    },
    dialogLogoPath() {
      return this.bookingDetails?.zone?.loyaltyFlags?.dialog_logo_path !==
        null &&
        this.bookingDetails?.zone?.loyaltyFlags?.dialog_logo_path !==
        undefined &&
        this.bookingDetails?.zone?.loyaltyFlags?.dialog_logo_path != ""
        ? this.bookingDetails?.zone?.loyaltyFlags?.dialog_logo_path
        : "../assets/newGoldenNugget.jpg";
    },
    logoHeight() {
      return Object.hasOwnProperty.call(this.bookingDetails, "booking") &&
        Object.hasOwnProperty.call(
          this.bookingDetails.booking,
          "TypeOfBooking"
        ) &&
        this.bookingDetails.booking.TypeOfBooking == "Self reservation"
        ? "100px"
        : "150px";
    },
    computedDateFormatted() {
      return this.time !== null && this.time !== "" && this.time !== undefined
        ? format(this.time, "yyyy/MM/dd")
        : new Date();
    },
    formattedTime() {
      return this.time ? format(this.time, "hh:mm aa") : "";
    },
    formattedDateTime() {
      return this.time !== null && this.time !== ""
        ? format(this.time, "iii, LLL dd")
        : "";
    },
    formattedYear() {
      return this.time !== null && this.time !== ""
        ? format(this.time, "yyyy")
        : "";
    },
    formattedRewardsDateTime() {
      return this.rewardsTime !== null && this.rewardsTime !== ""
        ? format(this.rewardsTime, "iii, LLL dd")
        : "";
    },
    formattedRewardsYear() {
      return this.rewardsTime !== null && this.rewardsTime !== ""
        ? format(this.rewardsTime, "yyyy")
        : "";
    },
    formattedRewardsTime() {
      return this.rewardsTime ? format(this.rewardsTime, "hh:mm aa") : "";
    },

    computedRewardsDateFormatted() {
      return this.rewardsTime !== null && this.rewardsTime !== "" && this.rewardsTime !== undefined
        ? format(this.rewardsTime, "dd MMM")
        : new Date();
    },
    formattedEntryDisplayTime() {
      return Object.hasOwnProperty.call(this.bookingDetails, "booking") &&
        Object.hasOwnProperty.call(this.bookingDetails.booking, "entrytime") &&
        this.bookingDetails.booking.entrytime !== null &&
        this.bookingDetails.booking.entrytime !== undefined &&
        this.bookingDetails.booking.entrytime !== ""
        ? format(
          new Date(
            this.formatExitDateTime(
              this.bookingDetails?.booking?.entrytime,
              "date"
            )
          ),
          "hh:mm a"
        )
        : format(
          dateToTimeZoneDate(new Date(), this.bookingDetails?.zone?.timezone),
          "hh:mm a"
        );
    },
    singleQuotes() {
      return Object.hasOwnProperty.call(this.bookingDetails, "zone") &&
        Object.hasOwnProperty.call(
          this.bookingDetails.zone,
          "timePickerConfiguration"
        ) &&
        this.bookingDetails.zone.timePickerConfiguration == "0"
        ? (this.time !== null &&
          this.time !== undefined &&
          this.time !== "" &&
          this.minDate !== null &&
          this.minDate !== undefined &&
          this.minDate !== "" &&
          format(this.time, "yyyy-MM-dd") ==
          format(this.minDate, "yyyy-MM-dd")) ||
        (this.beforeEstimate !== null &&
          this.beforeEstimate !== undefined &&
          this.beforeEstimate !== "" &&
          this.estimate !== null &&
          this.estimate !== undefined &&
          this.estimate !== "" &&
          this.beforeEstimate.grandTotal == this.estimate.grandTotal)
        : true;
    },
    doubleQuotes() {
      return Object.hasOwnProperty.call(this.bookingDetails, "zone") &&
        Object.hasOwnProperty.call(
          this.bookingDetails.zone,
          "timePickerConfiguration"
        ) &&
        this.bookingDetails.zone.timePickerConfiguration == "0"
        ? this.time !== null &&
        this.time !== undefined &&
        this.time !== "" &&
        this.minDate !== null &&
        this.minDate !== undefined &&
        this.minDate !== "" &&
        format(this.time, "yyyy-MM-dd") !=
        format(this.minDate, "yyyy-MM-dd") &&
        this.beforeEstimate !== null &&
        this.beforeEstimate !== undefined &&
        this.beforeEstimate !== "" &&
        this.estimate !== null &&
        this.estimate !== undefined &&
        this.estimate !== "" &&
        this.beforeEstimate.grandTotal != this.estimate.grandTotal
        : false;
    },
    disableLoyalty() {
      let disabled =
        this.loyalty24knum === undefined ||
        this.loyalty24knum === null ||
        this.loyalty24knum == "";
      return disabled;
    },
    disableExtend() {
      let disabled =
        this.time === null ||
        this.time === "" ||
        this.time === undefined ||
        this.estimate === null ||
        this.estimate === undefined ||
        Object.keys(this.estimate).length === 0 ||
        (this.bookingDetails?.zone?.timePickerConfiguration == "0" &&
          format(this.time, "yyyy-MM-dd") == format(this.maxDate, "yyyy-MM-dd")
          ? true
          : false);
      return disabled;
    },
    countDownExitTime() {
      if (this.exitDateTime < 0) {
        this.$store.commit("SET_VALIDATED_TIME_STATUS", true);
        clearInterval(this.exitTimeInterval);
      }
      return secondsToHms(this.exitDateTime / 1000 + 59);
    },
    countDownEstimateTime() {
      if (this.exitDateTime < 0) {
        this.$store.commit("SET_VALIDATED_TIME_STATUS", true);
        clearInterval(this.exitTimeInterval);
      }
      return secondsToHms(this.exitDateTime / 1000 + 59);
    },
    isExtra() {
      return window.location.pathname.includes("/gx/");
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
    showPaymentCardAdded: function getCardStatus() {
      var cardOnFile = this.bookingDetails?.booking?.cardOnfile
        ? this.bookingDetails.booking.cardOnfile
        : null;
      var validatedState = this.bookingDetails?.booking?.validated
        ? this.bookingDetails.booking.validated
        : null;
      var state =
        cardOnFile == "1"
          ? true
          : cardOnFile == "2" &&
          validatedState == "1" &&
          this.validatedDateTime > 0;
      if (this.bookingDetails?.booking?.eventModeTxn == "1") {
        return true;
      }
      return state;
    },
    timeAgo: function getTimeAgo() {
      return this.countUpTime;
    },
    validationPending: function qrValidation() {
      return this.isValid === undefined && this.camera === "off";
    },
    booking_content() {
      let dateTime = formatDateTime(
        Number(this.bookingDetails.booking.entrytime)
      );
      return dateTime[0] + " on " + dateTime[1] + " " + dateTime[2];
    },
    checkin_content() {
      return this.formatExitDateTime(
        this.bookingDetails.booking.entrytime,
        "checkin"
      );
    },
    checkout_content() {
      return (
        "Checkout before " +
        this.formatExitDateTime(this.bookingDetails.booking.revEnd, "expire")
      );
    },
    validated_content() {
      if (this.bookingDetails?.zone?.timezoneoffset) {
        let dateTime =
          this.bookingDetails.booking.type == "13"
            ? formatDateTime(
              Number(this.bookingDetails.booking.revStart) +
              Number(this.bookingDetails.booking.couponValidTime)
            )
            : formatDateTime(
              Number(this.bookingDetails.booking.entrytime) +
              Number(this.bookingDetails.booking.couponValidTime)
            );
        return dateTime[0] + ", " + dateTime[1] + " " + dateTime[2];
      }
      return "";
    },
    countDownValidatedTime() {
      if (this.validatedDateTime < 0) {
        this.$store.commit("SET_VALIDATED_TIME_STATUS", true);
        clearInterval(this.validatedInterval);
      }
      return this.secondsToHms(this.validatedDateTime / 1000 + 59);
    },
    countDownGracePeriodTime() {
      if (this.gracePeriodTime < 0) {
        this.$store.commit("SET_GRACE_PERIOD_TIME_STATUS", true);
        clearInterval(this.gracePeriodInterval);
      }
      //add 59 sec to graceperiod time to skip 0 min.
      return this.secondsToHms(this.gracePeriodTime / 1000 + 59);
    },
    // gracePeriodCountDownTime,
    // countDownValidationtime(){
    //   return
    // }
  },
  watch: {
    //check if there is any value in outstanding payments and calling booking api for it
  },
  mounted: function () {
    var self = this;
    this.getBookingDetails();
    EventBus.$on("GET_FREE_BOOKING", () => {
      this.getFreeBookingDetails();
    });
    EventBus.$on("SET_OUT_STANDING_PAYMENT_FLAG", (flag) => {
      self.outStandingPayments = flag;
    });
    EventBus.$on("SQUARE_PAY_CHECKEDIN", async (tokenResult, verfiedToken, paymentType) => {
      await self.squarePay(tokenResult, verfiedToken, paymentType)
    })
    EventBus.$on("SET_TIME", () => {
      this.onTimePickerChange();
      this.setRewardsTime();
    });
    this.exitDateTime = this.calculateExitDateTime();
    this.exitTimeInterval = setInterval(() => {
      if (this.bookingDetails?.zone?.timezone) {
        this.exitDateTime = this.calculateExitDateTime();
      }
    }, 1000);
    if (
      this.entryTime != null &&
      !this.bookingDetails?.booking?.exittime &&
      this.bookingDetails?.zone?.timezone &&
      this.bookingDetails?.booking?.gracePeriod
    ) {
      this.countUpTime = this.calculateParkingTime(
        this.entryTime,
        this.bookingDetails.booking.gracePeriod,
        this.bookingDetails.zone.timezone
      );
    }
    this.interval = setInterval(() => {
      if (
        this.entryTime != null &&
        !this.bookingDetails?.booking?.exittime &&
        this.bookingDetails?.zone?.timezone &&
        this.bookingDetails?.booking?.gracePeriod
      ) {
        this.countUpTime = this.calculateParkingTime(
          this.entryTime,
          this.bookingDetails.booking.gracePeriod,
          this.bookingDetails.zone.timezone
        );
      }
    }, 1000);
    if (
      this.bookingDetails?.booking?.exittime &&
      this.bookingDetails.booking.exittime != null &&
      this.bookingDetails.booking.exittime != ""
    ) {
      clearInterval(this.interval);
    }
    if (
      this.bookingDetails?.booking?.validated &&
      this.bookingDetails.booking.validated == "1" &&
      this.bookingDetails?.booking?.validationType &&
      (this.bookingDetails.booking.validationType == "DIVRT" ||
        this.bookingDetails.booking.validationType == "TICKET_TRANSFER" || this.bookingDetails.booking.validationType == "Time Reduction")
    ) {
      this.validatedDateTime = this.calculateValidatedTime();
    }

    this.validatedInterval = setInterval(() => {
      if (
        this.bookingDetails?.zone?.timezone &&
        this.bookingDetails?.booking?.validated &&
        this.bookingDetails.booking.validated == "1" &&
        this.bookingDetails?.booking?.validationType &&
        (this.bookingDetails.booking.validationType == "DIVRT" ||
          this.bookingDetails.booking.validationType == "TICKET_TRANSFER" || this.bookingDetails.booking.validationType == "Time Reduction")
      ) {
        this.validatedDateTime = this.calculateValidatedTime();
      }
    }, 1000);
    if (
      this.bookingDetails?.booking?.validated &&
      this.bookingDetails.booking.validated == "1" &&
      this.bookingDetails?.booking?.validationType &&
      (this.bookingDetails.booking.validationType == "DIVRT" ||
        this.bookingDetails.booking.validationType == "TICKET_TRANSFER" || this.bookingDetails.booking.validationType == "Time Reduction")
    ) {
      if (this.validatedDateTime < 0) {
        this.$store.commit("SET_VALIDATED_TIME_STATUS", true);
        clearInterval(this.validatedInterval);
      }
    }

    if (this.bookingDetails?.booking?.gracePeriod > 0) {
      this.gracePeriodTime = this.calculateGracePeriodTime();
    }

    this.gracePeriodInterval = setInterval(() => {
      if (this.bookingDetails?.zone?.timezone) {
        this.gracePeriodTime = this.calculateGracePeriodTime();
      }
    }, 1000);
    if (this.bookingDetails?.booking?.gracePeriod > 0) {
      if (this.gracePeriodTime < 0) {
        this.$store.commit("SET_GRACE_PERIOD_TIME_STATUS", true);
        clearInterval(this.gracePeriodInterval);
      }
    }
    EventBus.$on("sms-qr-result", (result) => {
      this.onDecode(result);
    });
    EventBus.$on("validation-qr-result", (result) => {
      this.onValidateQRCode(result);
    });

    // if(this.bookingDetails?.zone?.isPedestrian=='1'){
    //   this.pedestrianAccess=true
    // }
  },
  destroyed() {
    EventBus.$off('SQUARE_PAY_CHECKEDIN');
    clearInterval(this.interval);
    clearInterval(this.validatedInterval);
    clearInterval(this.gracePeriodInterval);
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.bookingDetails?.booking) {
        vm.getFreeBookingDetails();
      }
      vm.getMobileOperatingSystem() === "iOS"
        ? (vm.iosDevice = true)
        : (vm.iosDevice = false);
      if (
        vm.bookingDetails?.booking?.exittime &&
        vm.bookingDetails.booking.exittime != null &&
        vm.bookingDetails.booking.exittime != ""
      ) {
        clearInterval(vm.interval);
      }
      if (vm.bookingDetails?.booking?.validated == "0") {
        if (vm.validatedDateTime < 0) {
          vm.$store.commit("SET_VALIDATED_TIME_STATUS", true);
          clearInterval(vm.validatedInterval);
        }
      }
    });
  },
  methods: {
    rewardCardChange(){
      if(this.saveRewardCardFlag==false)
      {
        this.rentalCarFlag = false
      }
    },
    setRewardsTime(){
      this.openRewardsTimePicker = false
    },
    openUpdateVehicleDetails(){
      this.setRewardsDefaultDate();
      this.updateRewardCardFlag = true;
    },
    async updateRewardCard(){
      try {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: true,
        });
        var result = await bookingsvcAPIHelper(
          "POST",
          "/api/v1/updateRewardCardDetails",
          { bid: this.bookingDetails?.booking?.bid  , rentalCarValidTill: format(this.rewardsTime, 'yyyy-MM-dd hh:mm:ss')}
        );
        if (result?.data?.status) {
          this.$store.commit("SET_LOADING_STATUS", {
            property: "APP",
            pending: false,
          });
          await this.getBookingDetails()
          this.confirmRewardsDialog = false;
          this.updateRewardCardFlag = false;
          this.alertMsg =
            result?.data?.message || "Error in updating card";
          this.successDialog = true;

        }
      }
      catch (error) {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
        this.confirmRewardsDialog = false

        console.log(error)

      }
    },
    async removeSavedRewardCard() {
      try {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: true,
        });
        var removeCard = await bookingsvcAPIHelper(
          "POST",
          "/api/v1/removeRewardCardDetails",
          { bid: this.bookingDetails?.booking?.bid }
        );
        if (removeCard?.data?.status) {
          this.$store.commit("SET_LOADING_STATUS", {
            property: "APP",
            pending: false,
          });
          await this.getBookingDetails()
          this.removeRewardCardDialog = true;
          this.confirmRewardsDialog = false
          this.rewardCardMsg = removeCard?.data?.message

        }
      }
      catch (error) {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
        this.confirmRewardsDialog = false

        console.log(error)

      }
    },

    openRewardsDialog() {
      this.setRewardsDefaultDate()
      this.loyalty = true;
    },
    async squarePay(tokenResult, verfiedToken, paymentType) {
      let cardData = {};
      if (
        this.bookingDetails != null &&
        Object.hasOwnProperty.call(this.bookingDetails, "zone")
      ) {
        cardData = {
          userDevice: "2",
          orderType: "3",
          entry: [
            {
              locationCode: this.bookingDetails?.zone?.locationCode,
              startAt: this.bookingDetails?.booking?.startAt,
              quantity: "1",
            },
          ],
          paymentType: paymentType,
          billingDetails: tokenResult?.details?.billing,
          verification_token: verfiedToken,
          source_id: tokenResult.token,
          phoneNumber: "+" + this.bookingDetails.user.contact,
          email: tokenResult?.details?.shipping?.contact?.email || "",
        };
        try {
          this.$store.commit("SET_LOADING_STATUS", {
            property: "APP",
            pending: true,
          });
          var addCard = await APIHelper(
            "POST",
            "/api/v1/payment/addCard/" + this.bookingDetails?.booking?.bid,
            cardData
          );
          if (addCard?.data?.status == false) {
            this.$store.commit("SET_LOADING_STATUS", {
              property: "APP",
              pending: false,
            });
            this.gPayBtnLoading = false;
            this.alertMsg =
              addCard?.data?.message || "Error in authorizing the payment";
            this.alertIcon = "mdi-alert-outline";
            this.alertDialog = true;
          } else {
            this.$store.commit("SET_LOADING_STATUS", {
              property: "APP",
              pending: false,
            });
            this.gPayBtnLoading = false;
            //url segment is app mixin computed property
            let url =
              window.location.origin + '/g/' + this.bookingDetails?.booking?.bid;
            this.$store.commit("SET_LOADING_STATUS", {
              property: "APP",
              pending: false,
            });
            window.location.replace(url);
          }
        } catch (error) {
          this.$store.commit("SET_LOADING_STATUS", {
            property: "APP",
            pending: false,
          });
          this.gPayBtnLoading = false;
          console.log(error);
        }
      }
    },
    getFreeBookingDetails() {
      const currentTime = dateToTimeZoneDate(
        new Date(),
        this.bookingDetails?.zone?.timezone
      );
      if (localStorage.getItem("freeParkingDetails")) {
        let freeParkingDetails = localStorage.getItem("freeParkingDetails");
        freeParkingDetails = freeParkingDetails
          ? JSON.parse(freeParkingDetails)
          : {};
        const freeCouponValidTill =
          freeParkingDetails?.couponToRedeemFreeParkingValidTill
            ? freeParkingDetails.couponToRedeemFreeParkingValidTill
            : "";
        const couponValidTill = new Date(freeCouponValidTill);
        this.couponforfreeParkValidTill =
          isValid(couponValidTill) === true
            ? format(couponValidTill, "hh:mm a")
            : "";
        if (
          isValid(couponValidTill) === false ||
          currentTime.getTime() > couponValidTill.getTime() ||
          this.bookingDetails.booking.validated == "1"
        ) {
          this.applyValidationDialog = false;
          localStorage.removeItem("freeParkingDetails");
          this.freeParkingCouponCode = "";
          return;
        }
        this.freeParkingCouponCode =
          freeParkingDetails?.couponToRedeemFreeParking
            ? freeParkingDetails.couponToRedeemFreeParking
            : "";
        this.freeParkingBy = freeParkingDetails?.couponToRedeemFreeParking
          ? freeParkingDetails.freeParkingBy
          : "";
        this.mappedlocationForFreePark =
          freeParkingDetails?.mappedLocationForFreeParking
            ? freeParkingDetails.mappedLocationForFreeParking
            : [];
        if (
          this.mappedlocationForFreePark.includes(
            this.bookingDetails?.zone?.zid
          )
        ) {
          this.applyValidationDialog = true;
        }
      }
    },
    applyValidation() {
      this.validateBookingTxt = this.freeParkingCouponCode;
      this.onValidateText();
      this.applyValidationDialog = false;
      localStorage.removeItem("freeParkingDetails");
    },
    closeInValidSmsDialog() {
      this.invalidSMSDialog = false;
      // if(this.validateBookingDialog===false && this.loyalty==false && this.smsDialog==false){
      //    this.validateBookingDialog=true
      // }
    },
    formatExitDateTime(time, val) {
      if (time !== null && time !== undefined && time != "") {
        let utcDate = new Date(time * 1000).toUTCString(); //"Wed, 27 Jan 2021 13:59:04 GMT"
        let month = utcDate.split(",")[1].split(" ")[2]; // Jan
        let date = utcDate.split(",")[1].split(" ")[1]; // 27
        let year = utcDate.split(",")[1].split(" ")[3];
        let hr = utcDate.split(",")[1].split(" ")[4].split(":")[0]; // 13
        let min = utcDate.split(",")[1].split(" ")[4].split(":")[1]; //59
        let formatHr = Number(hr) > 12 ? Number(hr) - 12 : Number(hr); // 1
        formatHr = Number(hr) == 0 ? 12 : formatHr;

        formatHr = Number(formatHr) >= 10 ? Number(formatHr) : "0" + formatHr; //01
        let amOrpm = Number(hr) >= 12 ? "p" : "a"; //PM
        let ampm = Number(hr) >= 12 ? "PM" : "AM";
        switch (val) {
          case "hour": {
            return formatHr + amOrpm;
          }
          case "mins": {
            return min;
          }
          case "date": {
            return (
              month +
              " " +
              date +
              ", " +
              year +
              " " +
              formatHr +
              ":" +
              min +
              " " +
              ampm
            );
          }
          case "expire": {
            return (
              formatHr + ":" + min + " " + ampm + " on " + month + " " + date
            );
          }
          case "checkin": {
            return (
              formatHr + ":" + min + " " + ampm + " on " + month + " " + date
            );
          }
        }
      }
      //Jan 27, 01:59 PM
    },
    round(time) {
      let exitdate =
        time !== null && time !== "" ? format(time, "yyyy/MM/dd") : new Date();
      let selectedMins =
        time !== null && time !== "" ? format(time, "mm") : new Date();
      let selectedHours =
        time !== null && time !== "" ? format(time, "hh") : new Date();
      let selectedAMPM =
        time !== null && time !== "" ? format(time, "a") : new Date();
      if (
        exitdate != null &&
        selectedMins != "15" &&
        selectedMins != "30" &&
        selectedMins != "45" &&
        selectedMins != "00" &&
        selectedMins != null &&
        selectedMins != "" &&
        selectedHours != null &&
        selectedHours != "" &&
        selectedAMPM != null &&
        selectedAMPM != ""
      ) {
        let intervalMilliseconds = 900000;
        let datetime = new Date(
          exitdate +
          " " +
          selectedHours +
          ":" +
          selectedMins +
          " " +
          selectedAMPM
        );
        let interval = datetime.getTime() % intervalMilliseconds;
        let delta = interval === 0 ? 0 : datetime.getTime() - interval;
        delta += intervalMilliseconds;
        // this.exitTimeSelected = Object.assign(this.exitTimeSelected, { hh: format(new Date(delta), 'hh'), mm: format(new Date(delta), 'mm'), a: format(new Date(delta), 'a'), })
        return new Date(delta);
      }
    },
    loyaltyValid() {
      if (!/^[a-z0-9]+$/i.test(this.loyalty24knum)) {
        this.loyalty24knum = this.loyalty24knum.replace(/[^\w\s]/gi, "");
      }
    },
    async submitLoyalty(code) {
      try {
        this.validateBookingPending = true;
        let rentalCarValidTill = format(this.rewardsTime, 'yyyy-MM-dd hh:mm:ss')
        let result = await this.validateBooking(code, "loyalty", this.saveRewardCardFlag, this.rentalCarFlag, rentalCarValidTill);
        if (!result.data.status) {
          this.invalidSMSMsg = result?.data?.message
            ? result.data.message
            : "Validation not recognized. Please try again";
          this.invalidSMSDialog = true;
          this.validateBookingPending = false;
          this.postToLoggerAPI({
            phone: this.bookingDetails.user.contact,
            refId: this.bookingId,
            error: this.invalidSMSMsg,
            icon: "redeem",
            statusText:
              "Error in validating the session on entering validation code - " +
              this.validateBookingTxt,
          });
        } else {
          this.postToLoggerAPI({
            phone: this.bookingDetails.user.contact,
            refId: this.bookingId,
            icon: "redeem",
            statusText:
              "Parker validated the session successfully by entering validation code - " +
              this.validateBookingTxt,
          });
          this.validateBookingPending = false;
          this.validateBookingDialog = false;
          this.$store.commit(
            "SET_VALIDATED_MERCHANT",
            result?.data?.merchantName
          );
          this.loyalty = false;
          this.alertMsg =
            result?.data?.message || "Error in authorizing the payment";
          this.successDialog = true;
        }
      } catch (error) {
        console.log(error);
        this.validateBookingPending = false;
      }
    },
    closeAlertDialog() {
      this.alertDialog = false;
    },
    getPaymentType(type) {
      switch (type) {
        case "APPLE_PAY": {
          return "Apple Pay";
        }
        case "GOOGLE_PAY": {
          return "Google Pay";
        }
        case "Card": {
          return "Card";
        }
      }
    },

    /** @methods to find the the UTC date 
    @params the bookings date value , type 'exit (2023-12-02)' or 'formatdate (12/02/2023)'
    */
    formatUTCDefaultDate(defaultDate, type) {
      let utcDate = new Date(defaultDate * 1000).toUTCString();
      let month = utcDate.split(",")[1].split(" ")[2]; // Jan
      let date = utcDate.split(",")[1].split(" ")[1]; // 27
      let year = utcDate.split(",")[1].split(" ")[3]; //2023
      let exitformat = format(
        new Date(month + " " + date + " " + year),
        "yyyy-MM-dd"
      );
      let dateformat = format(
        new Date(month + " " + date + " " + year),
        "MM/dd/yyyy"
      );
      return type === "exit" ? exitformat : dateformat;
    },
    async extendEDT() {
      try {
        //format(new Date(this.checkinDate), "yyyy-MM-dd'T'HH:mm:ss");
        var exitDateTime = ""
        this.extendBtnLoading = true;
        exitDateTime = this.time != null || this.time !== undefined || this.time != "" ?
                    format(this.bookingDetails?.zone.timePickerConfiguration == '0' && this.customEndTimeMinDate ? addDays(this.time, 1) : this.time, 'yyyy/MM/dd')
                    +
                    " " +
                    (this.bookingDetails?.zone?.timePickerConfiguration == '0' ? format24TimeToAMPM(this.bookingDetails?.zone?.customizedEndTime) : format(this.time, 'hh:mm a')) : ""
        let data = {
          bid: this.bookingDetails?.booking?.bid,
          extendedTime: format(new Date(exitDateTime), "yyyy-MM-dd HH:mm:ss"), //"2022-12-29T02:26:00.000",
        };
        var extendSessionRes = await APIHelper(
          "POST",
          "/api/v1/prepaid/extendReservation",
          data
        );
        if (extendSessionRes?.data?.message == "Successfully updated!!") {
          //passing the bid from the url path
          await this.getBookingDetails();
          this.extendBtnLoading = false;
          this.extensionDialog = false;
        } else {
          this.alertIcon = "mdi-alert-outline"
          this.alertDialog = true;
          this.alertMsg =
            extendSessionRes?.data?.message || "Error in extending the session";
        }
      } catch (error) {
        this.extendBtnLoading = false;
        console.log(error);
      }
    },
    formatDate(date) {
      let stringdate = date.toString();
      if (!date) return null;

      const [year, month, day] = stringdate.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async openExtDialog() {
      // await axios.get(
      //   process.env.VUE_APP_ON_DEMAND_URL + "/" + "32899"+ ".json"
      // );
      this.setDefaultDate();
      this.setDatePicker();
      // this.setTimePicker();
      this.extensionDialog = true;
      this.extendBtnLoading = false;
    },

    setDefaultDate() {
      //formatted exit date value to display
      this.dateFormatted = this.bookingDetails?.booking?.reservationEndDate
        ? this.formatUTCDefaultDate(
          this.bookingDetails?.booking?.revEnd,
          "format"
        )
        : "";
      this.time = new Date(
        this.formatExitDateTime(this.bookingDetails?.booking?.revEnd, "date")
      );
      this.exitDate = this.time;

      // this.dateTimeChange()
      // Object.hasOwnProperty.call(this.bookingDetails.booking, 'isGated') && this.bookingDetails.booking?.isGated == "1" && format(this.time, 'yyyy-MM-dd') != format(dateToTimeZoneDate(new Date(), this.bookingDetails?.zone?.timezone), 'yyyy-MM-dd') ? this.beforeDateTimeChange() : "";
    },

    setRewardsDefaultDate() {
      //formatted exit date value to display
      this.rewardsTime = Object.hasOwnProperty.call(this.bookingDetails, 'zone') && Object.hasOwnProperty.call(this.bookingDetails.zone , 'timezone')
        ?
        dateToTimeZoneDate(new Date(),
          this.bookingDetails.zone.timezone)
        : new Date()
      this.minDate = this.bookingDetails.zone.timezone
        ?
        dateToTimeZoneDate(new Date(),
          this.bookingDetails.zone.timezone)
        : new Date()
      let fifteenTime = this.rewardsTime;

      this.rewardsTime =
        format(this.rewardsTime, "mm") == "15" ||
          format(this.rewardsTime, "mm") == "30" ||
          format(this.rewardsTime, "mm") == "45" ||
          format(this.rewardsTime, "mm") == "00"
          ? fifteenTime
          : this.round(this.rewardsTime)
      // this.dateTimeChange()
      // Object.hasOwnProperty.call(this.bookingDetails.booking, 'isGated') && this.bookingDetails.booking?.isGated == "1" && format(this.time, 'yyyy-MM-dd') != format(dateToTimeZoneDate(new Date(), this.bookingDetails?.zone?.timezone), 'yyyy-MM-dd') ? this.beforeDateTimeChange() : "";
    },
    rewardsTimeChange() {
      this.openRewardsDatePicker = false;
    },
    onTimePickerChange() {
      let exitdate =
        this.time !== null && this.time !== undefined && this.time !== ""
          ? format(this.time, "yyyy/MM/dd")
          : "";
      let selectedMins =
        this.time !== null && this.time !== undefined && this.time !== ""
          ? format(this.time, "mm")
          : "";
      let selectedHours =
        this.time !== null && this.time !== undefined && this.time !== ""
          ? format(this.time, "HH")
          : "";
      let selectedAMPM =
        this.time !== null && this.time !== undefined && this.time !== ""
          ? format(this.time, "a")
          : "";
      this.stopTime =
        selectedMins != null &&
          selectedMins != "" &&
          selectedHours != null &&
          selectedHours != "" &&
          selectedAMPM != null &&
          selectedAMPM != ""
          ? selectedHours + ":" + selectedMins + ":" + "00"
          : "";
      let currenTime = this.bookingDetails.zone.timezone
        ? format(
          dateToTimeZoneDate(new Date(), this.bookingDetails.zone.timezone),
          "yyyy-MM-dd HH:mm:ss"
        )
        : format(new Date(), "yyyy-MM-dd  HH:mm:ss");
      if (
        selectedMins != null &&
        selectedMins != "" &&
        selectedHours != null &&
        selectedHours != "" &&
        selectedAMPM != null &&
        selectedAMPM != "" &&
        new Date(exitdate + " " + this.stopTime).getTime() >
        new Date(currenTime).getTime()
      ) {
        format(this.time, "yyyy-MM-dd") != format(this.minDate, "yyyy-MM-dd")
          ? this.beforeDateTimeChange()
          : "";
        this.dateTimeChange();
      }
    },
    setRewardsDatePicker() {
      //set the minimum date to today date(zone time zone date)
      this.minDate = this.bookingDetails.zone.timezone
        ? new Date(
          this.formatExitDateTime(
            this.bookingDetails?.booking?.revEnd,
            "date"
          )
        )
        : dateToTimeZoneDate(
          new Date(
            Number(this.bookingDetails?.booking?.revEnd) * 1000 -
            Number(this.bookingDetails.zone.timezoneoffset) * 1000
          ),
          this.bookingDetails.zone.timezone
        );
      //calculate max parkign time in seconds
      let maxPark =
        this.bookingDetails != null
          ? this.bookingDetails?.zone?.maxParkingTime
          : null;

      let addeddays = addDays(new Date(), maxPark?.days);
      let addedhours = addHours(addeddays, maxPark?.hours);
      let addedmins = addMinutes(addedhours, maxPark?.minutes);
      //get max date in seconds based on max parking time
      let maxZoneDate = addedmins;
      this.maxDate = this.bookingDetails?.zone.timezone
        ? dateToTimeZoneDate(maxZoneDate, this.bookingDetails?.zone?.timezone)
        : new Date(maxZoneDate);
      //get max date with time and set it to maxDateTime
      this.maxDateTime = this.bookingDetails?.zone?.timezone
        ? format(
          dateToTimeZoneDate(
            new Date(maxZoneDate),
            this.bookingDetails?.zone?.timezone
          ),
          "yyyy-MM-dd hh:mm aaaaa"
        )
        : format(new Date(), "yyyy-MM-dd hh:mm aaaaa");
    },
    setDatePicker() {
      //set the minimum date to today date(zone time zone date)
      this.minDate = this.bookingDetails.zone.timezone
        ? new Date(
          this.formatExitDateTime(
            this.bookingDetails?.booking?.revEnd,
            "date"
          )
        )
        : dateToTimeZoneDate(
          new Date(
            Number(this.bookingDetails?.booking?.revEnd) * 1000 -
            Number(this.bookingDetails.zone.timezoneoffset) * 1000
          ),
          this.bookingDetails.zone.timezone
        );
      //calculate max parkign time in seconds
      let maxPark =
        this.bookingDetails != null
          ? this.bookingDetails?.zone?.maxParkingTime
          : null;

      let addeddays = addDays(new Date(), maxPark?.days);
      let addedhours = addHours(addeddays, maxPark?.hours);
      let addedmins = addMinutes(addedhours, maxPark?.minutes);
      //get max date in seconds based on max parking time
      let maxZoneDate = addedmins;
      this.maxDate = this.bookingDetails?.zone.timezone
        ? dateToTimeZoneDate(maxZoneDate, this.bookingDetails?.zone?.timezone)
        : new Date(maxZoneDate);
      //get max date with time and set it to maxDateTime
      this.maxDateTime = this.bookingDetails?.zone?.timezone
        ? format(
          dateToTimeZoneDate(
            new Date(maxZoneDate),
            this.bookingDetails?.zone?.timezone
          ),
          "yyyy-MM-dd hh:mm aaaaa"
        )
        : format(new Date(), "yyyy-MM-dd hh:mm aaaaa");
    },
    // onOpen() {
    //   this.setTimePicker(this.exitTimeSelected);
    // },
    /** @methods to find the estimate rate 
       @params startDate , stopDate,zcode, type , env_overRide
       */
    async beforeDateTimeChange() {
      if (!this.bookingDetails.zone.zcode) {
        return;
      }
      try {
        // format(new Date(this.checkinDate), "yyyy-MM-dd'T'HH:mm:ss");

        let edtEntryTime = this.bookingDetails?.booking?.entrytime;
        this.startDate =
          edtEntryTime !== null &&
            edtEntryTime !== undefined &&
            edtEntryTime !== ""
            ? format(
              new Date(this.formatExitDateTime(edtEntryTime, "date")),
              "yyyy-MM-dd'T'HH:mm:ss"
            )
            : this.bookingDetails.zone.timezone
              ? format(
                dateToTimeZoneDate(
                  new Date(),
                  this.bookingDetails.zone.timezone
                ),
                "yyyy-MM-dd'T'HH:mm:ss"
              )
              : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
        let stopTime = this.stopTime;
        let selectedHours =
          this.bookingDetails.booking.entrytime != null &&
            this.bookingDetails.booking.entrytime !== undefined &&
            this.bookingDetails.booking.entrytime != ""
            ? format(
              new Date(
                this.formatExitDateTime(
                  this.bookingDetails?.booking?.entrytime,
                  "date"
                )
              ),
              "HH"
            )
            : format(
              dateToTimeZoneDate(
                new Date(),
                this.bookingDetails.zone.timezone
              ),
              "HH"
            );
        let selectedMins =
          this.bookingDetails.booking.entrytime != null &&
            this.bookingDetails.booking.entrytime !== undefined &&
            this.bookingDetails.booking.entrytime != ""
            ? format(
              new Date(
                this.formatExitDateTime(
                  this.bookingDetails?.booking?.entrytime,
                  "date"
                )
              ),
              "mm"
            )
            : format(
              dateToTimeZoneDate(
                new Date(),
                this.bookingDetails.zone.timezone
              ),
              "mm"
            );
        let exittime =
          Object.hasOwnProperty.call(this.bookingDetails, "zone") &&
            Object.hasOwnProperty.call(
              this.bookingDetails.zone,
              "timePickerConfiguration"
            ) &&
            this.bookingDetails.zone.timePickerConfiguration == "1"
            ? "T" + stopTime
            : "T" + selectedHours + ":" + selectedMins + ":00";
        let exitdate = format(this.time, "yyyy-MM-dd");
        let stopDate = exitdate + exittime;
        let data = {
          startAt: this.startDate,
          stopAt: stopDate,
          zcode: this.bookingDetails.zone.zcode,
        };
        this.addPaymentBtnLoading = true;
        var getRate = await APIHelper("POST", "/api/v2/getRate", data);
        //condition to check if the date time selected is lesser than current time
        if (!getRate?.data?.status && !this.getRateCall) {
          this.disableAddPaymentBtn = true;
          this.alertIcon = "mdi-alert-outline"
          this.alertDialog = true;
          this.alertMsg =
            getRate?.data?.message || "Error in getting rate details";
          this.openDatePicker = false;
          this.openTimePicker = false;
        } else {
          this.disableAddPaymentBtn = false;
          this.openDatePicker = false;
          this.openTimePicker = false;
        }
        this.beforeEstimate = Object.assign({}, getRate?.data?.data);
        // estimatedVal != "" &&
        // estimatedVal != null &&
        // estimatedVal != undefined
        //   ? Number(estimatedVal).toFixed(2)
        //   : estimatedVal;
        this.addPaymentBtnLoading = false;
      } catch (error) {
        this.addPaymentBtnLoading = false;
        console.log(error);
      }
    },
    async dateTimeChange() {
      if (!this.bookingDetails.zone.zcode) {
        return;
      }
      this.extendBtnLoading = true;

      try {
        // format(new Date(this.checkinDate), "yyyy-MM-dd'T'HH:mm:ss");
        let edtEntryTime = this.bookingDetails?.booking?.entrytime;
        this.startDate =
          edtEntryTime !== null &&
            edtEntryTime !== undefined &&
            edtEntryTime !== ""
            ? format(
              new Date(this.formatExitDateTime(edtEntryTime, "date")),
              "yyyy-MM-dd'T'HH:mm:ss"
            )
            : this.bookingDetails.zone.timezone
              ? format(
                dateToTimeZoneDate(
                  new Date(),
                  this.bookingDetails.zone.timezone
                ),
                "yyyy-MM-dd'T'HH:mm:ss"
              )
              : format(
                dateToTimeZoneDate(
                  new Date(),
                  this.bookingDetails.zone.timezone
                ),
                "yyyy-MM-dd'T'HH:mm:ss"
              );
        // console.log(this.exitTimeSelected?.hh+":"+this.exitTimeSelected.mm+":00")
        // this.exitDate + "T23:59:00";
        let selectedHours =
          this.time !== null && this.time !== "" && this.time !== undefined
            ? format(this.time, "HH")
            : format(
              dateToTimeZoneDate(
                new Date(),
                this.bookingDetails.zone.timezone
              ),
              "HH"
            );
        let selectedMins =
          this.time !== null && this.time !== "" && this.time !== undefined
            ? format(this.time, "mm")
            : format(
              dateToTimeZoneDate(
                new Date(),
                this.bookingDetails.zone.timezone
              ),
              "mm"
            );
        let exitdate =
          this.time !== null && this.time !== "" && this.time !== undefined
            ? format(this.time, "yyyy-MM-dd")
            : "";
        let exittime =
          Object.hasOwnProperty.call(this.bookingDetails, "zone") &&
            Object.hasOwnProperty.call(
              this.bookingDetails.zone,
              "timePickerConfiguration"
            ) &&
            this.bookingDetails?.zone?.timePickerConfiguration == "1"
            ? "T" + selectedHours + ":" + selectedMins + ":00"
            : `T${this.bookingDetails?.zone?.customizedEndTime}`;
        let stopDate = exitdate + exittime;

        let data = {
          startAt: this.startDate,
          stopAt: stopDate,
          zcode: this.bookingDetails?.zone?.zcode,
        };
        this.addPaymentBtnLoading = true;
        var getRate = await APIHelper("POST", "/api/v2/getRate", data);
        this.extendBtnLoading = false;
        this.extDateAndTime = stopDate;
        //condition to check if the date time selected is lesser than current time
        if (!getRate?.data?.status && !this.getRateCall) {
          this.disableAddPaymentBtn = true;
          this.alertIcon = "mdi-alert-outline"
          this.alertDialog = true;
          this.alertMsg =
            getRate?.data?.message || "Error in getting rate details";
          this.openDatePicker = false;
          this.openTimePicker = false;
          EventBus.$emit("datetime-set");
        } else {
          this.disableAddPaymentBtn = false;
          this.openDatePicker = false;
          this.openTimePicker = false;
          EventBus.$emit("datetime-set");
        }
        this.estimate = Object.assign({}, getRate?.data?.data);
        this.zoneEndTimeLabel = `You are eligible to stay till ${format(new Date(stopDate), 'MM-dd-yyyy hh:mm a')}` 
        // estimatedVal != "" &&
        // estimatedVal != null &&
        // estimatedVal != undefined
        //   ? Number(estimatedVal).toFixed(2)
        //   : estimatedVal;
        this.addPaymentBtnLoading = false;
      } catch (error) {
        this.addPaymentBtnLoading = false;
        console.log(error);
      }
    },
    calculateExitDateTime() {
      if (this.bookingDetails?.zone?.timezone) {
        //   var currentTime =
        // new Date().getTime() +
        // (Number(this.bookingDetails.zone.timezoneoffset) * 1000 +
        //   Number(this.bookingDetails.zone.timezonedstoffset) * 1000);
        var currentTime =
          new Date().getTime() +
          (Number(this.bookingDetails.zone.timezoneoffset) * 1000 +
            Number(this.bookingDetails.zone.timezonedstoffset) * 1000);
        var exitTime = new Date(
          this.bookingDetails.booking.reservationEndDate
        ).getTime();
        this.$store.commit("SET_EXIT_TIME_TIME_STATUS", this.exitDateTime < 0);
        exitTime - currentTime < 0 ? clearInterval(this.exitInterval) : "";
        return exitTime - currentTime;
      }
      return 0;
    },
    hotelGuest() {
      let url =
        window.location.origin +
        "?zcode=" +
        this.bookingDetails?.zone?.locationCode +
        "#/createsession";
      let plate = this.bookingDetails?.booking?.vehicleNo || "";
      let phone = this.bookingDetails?.user?.contact || "";
      let bid = this.bookingDetails?.booking?.bid || "";
      let checkindate = this.bookingDetails?.booking?.entrytime || "";
      let country =  this.bookingDetails?.booking?.plateRegion?.country || "";
      let state =  this.bookingDetails?.booking?.plateRegion?.state || "";
      let params =
        "/?phone=" +
        phone +
        "&bid=" +
        bid +
        "&plate=" +
        plate +
        "&entrytime=" +
        checkindate+ "&country=" +country+ "&state=" +state;
      window.location.replace(url + params);
      // this.$router.replace({path:'/'});
    },
    closeQRCodeDialog() {
      this.qrCodeDialog = false;
      this.cameraStatus = false;
    },
    closeValidationQRDialog() {
      this.validationCameraStatus = false;
      this.validateBookingDialog = true;
      this.validateQRCodeDialog = false;
    },
    reScanQR() {
      this.invalidQRDialog = false;
      this.cameraStatus = true;
    },
    reScanValidationQR() {
      this.invalidValidationQRDialog = false;
      this.validationCameraStatus = true;
    },
    openSmsQrScanner() {
      this.qrCodeDialog = true;
      this.cameraStatus = true;
    },
    openSMSDialog() {
      this.smsDialog = true;
      let exitLanes =
        this.exitGateCodes.length > 0
          ? this.exitGateCodes.filter((a) => a.isPedestrian == "0")
          : [];
      if (
        this.bookingDetails?.booking?.prePopulateGateCodes &&
        this.bookingDetails.booking.prePopulateGateCodes == "1"
      ) {
        this.smsText = exitLanes.length == 1 ? exitLanes[0].sms_code : "";
      }
    },
    scrollToSubmit() {
      const el = document.querySelector("#submitsmsbtn");
      if (el) {
        el.scrollIntoView(this.options);
      }
    },
    scrollToValidateBtn() {
      const el = document.querySelector("#validatebookingbtn");
      if (el) {
        el.scrollIntoView(this.options);
      }
    },
    calculateGracePeriodTime() {
      var currentTime =
        new Date().getTime() +
        (Number(this.bookingDetails.zone.timezoneoffset) * 1000 +
          Number(this.bookingDetails.zone.timezonedstoffset) * 1000);
      var gracePeriodTime =
        (Number(this.bookingDetails.booking.entrytime) +
          Number(this.bookingDetails.booking.gracePeriod)) *
        1000; //
      this.$store.commit(
        "SET_GRACE_PERIOD_TIME_STATUS",
        this.gracePeriodTime <= 0
      );
      return gracePeriodTime - currentTime;
    },
    calculateValidatedTime() {
      var currentTime =
        new Date().getTime() +
        (Number(this.bookingDetails.zone.timezoneoffset) * 1000 +
          Number(this.bookingDetails.zone.timezonedstoffset) * 1000);
      var validatedTime =
        this.bookingDetails.booking.type == "13"
          ? (Number(this.bookingDetails.booking.revStart) +
            Number(this.bookingDetails.booking.couponValidTime)) *
          1000
          : (Number(this.bookingDetails.booking.entrytime) +
            Number(this.bookingDetails.booking.couponValidTime)) *
          1000;
      this.$store.commit("SET_VALIDATED_TIME_STATUS", this.validatedTime < 0);
      validatedTime - currentTime < 0
        ? clearInterval(this.validatedInterval)
        : "";
      return validatedTime - currentTime;
    },
    calculateParkingTime(parkingStartTime, gracePeriod, zone) {
      var currentTime = new Date();
      currentTime.toLocaleString("en-US", { timeZone: zone });
      var differenceTime =
        gracePeriod > 0
          ? currentTime.getTime() - parkingStartTime * 1000 // - gracePeriod * 1000 to exclude grace period
          : currentTime.getTime() - parkingStartTime * 1000;
      let parkedTime = this.secondsToHms(differenceTime / 1000);
      return parkedTime;
    },
    secondsToHms(timeDifference) {
      var d = Number(timeDifference);
      // if (timeDifference >= 86400) {
      //   var days = Math.floor(d / 86400);
      //   var hrs = Math.floor((d % 86400) / 3600);
      //   var dayDisplay =
      //     days > 0 ? days + (days == 1 ? " day " : " days ") : "";
      //   var hrDisplay = hrs > 0 ? hrs + (hrs == 1 ? " hr " : " hrs ") : "";
      //   return dayDisplay + hrDisplay;
      // }
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      // var s = Math.floor(d % 3600 % 60);
      var hDisplay = h > 0 ? h + " h " : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " min " : " min") : " 0 min";
      // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      return hDisplay + mDisplay;
    },
    isCheckoutEnabled(type) {
      if (
        Object.hasOwnProperty.call(this.bookingDetails, "exitOptions") &&
        this.bookingDetails.exitOptions.length != 0
      ) {
        let option = this.bookingDetails.exitOptions.some(
          (t) => t.techType == type
        );
        return option;
      }
      return false;
    },
    async getBookingDetails() {
      this.loading = true;
      try {
        let bookingId = window.location.pathname.substring(3)
        var bookingDetails = await APIHelper(
          "GET",
          "/api/v1/booking/config/" + bookingId
        );
        this.$store.commit(
          "SET_BOOKING_DETAILS",
          bookingDetails.data?.data ? bookingDetails.data.data : null
        );
        if (this.bookingDetails?.outStandingPaymentDetails?.status) {
          this.outStandingPayments = this.bookingDetails
            ?.outStandingPaymentDetails?.status && this.bookingDetails?.outStandingPaymentDetails?.status == 'BLOCKED' && this.bookingDetails?.zone?.showOutstandingPayment == 1
            ? true
            : false;
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error);
      }
    },
    async getpedestrianAccess() {
      try {
        // let bookingId = window.location.pathname.substring(3);
        var pedesAccess = await APIHelper(
          "POST",
          "api/v1/open/" + this.bookingId,
          { source: "webView" }
        );
        // console.log(pedesAccess)
        // console.log(pedesAccess?.data?.status)
        if (pedesAccess?.data?.status) {
          this.postToLoggerAPI({
            phone: this.bookingDetails.user.contact,
            refId: this.bookingId,
            image: "https://sppl-dev.divrt.co/img/pedestrain_door.042f0927.png",
            statusText: "Parker accessed pedestrian door successfully.",
          });
          this.$router.replace({ path: "/pedestrianaccess" });
        } else {
          this.pedestrianAccessText = pedesAccess.data.message;
          this.postToLoggerAPI({
            phone: this.bookingDetails.user.contact,
            refId: this.bookingId,
            error: this.pedestrianAccessText,
            image: "https://sppl-dev.divrt.co/img/pedestrain_door.042f0927.png",
            statusText: "Error in opening the pedestrian door.",
          });
          this.pedestrianAccessDialog = true;
        }
      } catch (error) {
        console.log(error);
      }
      // this.$router.replace({ path: "/pedestrianaccess/"+true });
    },
    navigateToAddPayment() {
      this.postToLoggerAPI({
        phone: this.bookingDetails.user.contact,
        refId: this.bookingId,
        statusText: "Parker clicked on ADD CREDIT CARD btn.",
      });
      let parkingDisclosure = {
        content: this.bookingDetails?.zone?.mySpotSettings?.parkingDisclosures,
      };
      this.$router.replace({
        name: "AddPayment",
        params: { parkingDisclosure },
      });
    },
    openValidationDialog() {
      this.validateBookingDialog = true;
    },
    async openSMSApp() {
      this.postToLoggerAPI({
        phone: this.bookingDetails.user.contact,
        refId: this.bookingId,
        statusText: "Parker clicked on SMS submit btn.",
      });
      if (this.smsText == null || this.smsText == "") {
        this.invalidSMSMsg = "Gate code cannot be empty";
        this.invalidSMSDialog = true;
      } else {
        try {
          this.smsPending = true;
          var preValidateSendSMS = await APIHelper("POST", "/receive_sms", {
            bid: this.bookingId,
            From: this.bookingDetails.user.contact,
            Text: this.smsText,
            To: this.bookingDetails.zone.ToMobileNo,
            Mode: "SMS",
            webView: "1",
            preValidate: 1,
          });
          this.smsPending = false;
          if (
            preValidateSendSMS?.data?.status &&
            preValidateSendSMS.data.status
          ) {
            this.smsDialog = false;
            this.$router.replace({ path: "/success" });
            try {
              var sendSMS = await APIHelper("POST", "/receive_sms", {
                bid: this.bookingId,
                From: this.bookingDetails.user.contact,
                Text: this.smsText,
                To: this.bookingDetails.zone.ToMobileNo,
                Mode: "SMS",
                webView: "1",
              });
              if (sendSMS?.data?.status && sendSMS.data.status) {
                this.smsDialog = false;
                this.postToLoggerAPI({
                  phone: this.bookingDetails.user.contact,
                  refId: this.bookingId,
                  icon: "logout",
                  statusText:
                    "Parker checked out successfully using SMS - " +
                    this.smsText,
                });
              } else {
                this.invalidSMSMsg = sendSMS?.data?.message
                  ? sendSMS.data.message
                  : "Please try again";
                this.invalidSMSDialog = true;
                this.postToLoggerAPI({
                  phone: this.bookingDetails.user.contact,
                  refId: this.bookingId,
                  error: this.invalidSMSMsg,
                  icon: "logout",
                  statusText:
                    "Error occured while checking out using SMS - " +
                    this.smsText,
                });
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            this.invalidSMSMsg = preValidateSendSMS?.data?.message
              ? preValidateSendSMS.data.message
              : "Please try again";
            this.invalidSMSDialog = true;
            this.postToLoggerAPI({
              phone: this.bookingDetails.user.contact,
              refId: this.bookingId,
              error: this.invalidSMSMsg,
              icon: "logout",
              statusText:
                "Error occured while checking out using SMS - " + this.smsText,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      return "unknown";
    },
    getExitIcon(exitId) {
      let exitDetails = this.bookingDetails.exitOptions.find(
        (p) => p.techType == exitId
      );
      return exitDetails.icon;
    },
    getExitText(exitId) {
      let exitDetails = this.bookingDetails.exitOptions.find(
        (p) => p.techType == exitId
      );
      return exitDetails.name;
    },
    openDialerApp() {
      this.postToLoggerAPI({
        phone: this.bookingDetails.user.contact,
        refId: this.bookingId,
        statusText: "Parker clicked on dialer submit btn.",
      });
      this.dialerDialog = false;
      var url = "tel:" + this.bookingDetails.zone.ToMobileNo;
      window.location.href = url;
      if (this.getMobileOperatingSystem() == "Android") {
        window.close();
      }
    },
    turnCameraOn() {
      this.camera = "auto";
    },

    turnCameraOff() {
      this.camera = "off";
    },
    validateDecodedString(str) {
      let validateDivrtStr = str.startsWith("divrt://");
      let validateMMID = str.split("/")[2] == this.bookingDetails.booking.mmid;
      let validateMajorId =
        str.split("/")[3] == this.bookingDetails.booking.majorid;
      let validateMinorId = this.bookingDetails.booking.minor_ids.includes(
        str.split("/")[4]
      );
      return (
        validateDivrtStr && validateMMID && validateMajorId && validateMinorId
      );
    },
    async onDecode(decodedString) {
      var self = this;
      this.qrCode = decodedString;
      this.cameraStatus = false;
      try {
        var preValidateQrResult = await APIHelper(
          "POST",
          "/api/v1/checkout/" + this.bookingId,
          {
            beaconId: decodedString,
            preValidate: 1,
            zid: this.bookingDetails.zone.locationCode,
          }
        );
        if (preValidateQrResult.data.status) {
          try {
            self.$router.replace({ path: "/success" });
            var qrResult = await APIHelper(
              "POST",
              "/api/v1/checkout/" + this.bookingId,
              {
                beaconId: decodedString,
                zid: this.bookingDetails.zone.locationCode,
              }
            );
            if (qrResult.data.status) {
              this.postToLoggerAPI({
                phone: self.bookingDetails.user.contact,
                refId: self.bookingId,
                icon: "logout",
                statusText:
                  "Parker successfully checked out using QR - " + decodedString,
              });
              this.$store.commit("SET_CHECKOUT", true);
            } else {
              this.invalidQRMsg = qrResult?.data?.message
                ? qrResult.data.message
                : "Please try again";
              this.invalidQRDialog = true;
              this.postToLoggerAPI({
                phone: self.bookingDetails.user.contact,
                refId: self.bookingId,
                error: self.invalidQRMsg,
                icon: "logout",
                statusText:
                  "Error Occured while checking out using QR - " +
                  decodedString,
              });
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          this.invalidQRMsg = preValidateQrResult?.data?.message
            ? preValidateQrResult.data.message
            : "Please try again";
          this.invalidQRDialog = true;
          this.postToLoggerAPI({
            phone: self.bookingDetails.user.contact,
            refId: self.bookingId,
            icon: "logout",
            error: self.invalidQRMsg,
            statusText:
              "Error Occured while checking out using QR - " + decodedString,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.invalidQRMsg = "Please grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.invalidQRMsg = "No camera found on this device";
        } else if (error.name === "NotSupportedError") {
          this.invalidQRMsg = "Secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.invalidQRMsg = "Is the camera already in use? Please check.";
        } else if (error.name === "OverconstrainedError") {
          this.invalidQRMsg = "Installed camera is not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.invalidQRMsg = "Stream API is not supported in this browser";
        }
        this.invalidQRDialog = true;
      }
    },
    async onValidateText() {
      this.validateBookingPending = true;
      let result = await this.validateBooking(
        this.validateBookingTxt,
        "coupon"
      );
      if (!result.data.status) {
        this.invalidSMSMsg = result?.data?.message
          ? result.data.message
          : "Validation not recognized. Please try again";
        this.invalidSMSDialog = true;
        this.validateBookingPending = false;
        this.postToLoggerAPI({
          phone: this.bookingDetails.user.contact,
          refId: this.bookingId,
          error: this.invalidSMSMsg,
          icon: "redeem",
          statusText:
            "Error in validating the session on entering validation code - " +
            this.validateBookingTxt,
        });
      } else {
        this.postToLoggerAPI({
          phone: this.bookingDetails.user.contact,
          refId: this.bookingId,
          icon: "redeem",
          statusText:
            "Parker validated the session successfully by entering validation code - " +
            this.validateBookingTxt,
        });
        this.goldenNugget = false;
        this.validateBookingPending = false;
        this.validateBookingDialog = false;
        this.$store.commit(
          "SET_VALIDATED_MERCHANT",
          result?.data?.merchantName
        );
        const isFreeparkingRedeemed =
          result?.data?.message === "Free Parking redeemed" ? true : false;
        this.$store.commit(
          "SET_REDEEMED_FREE_PARKING_STATUS",
          isFreeparkingRedeemed
        );
        this.$router.replace({ path: "/validatesuccess" });
      }
    },
    async validateBooking(code, type, saveRewardCard, isRentalCar, rentalCarValidTill) {
      try {
        let validateResult = await APIHelper(
          "POST",
          "/api/v1/validate/" + this.bookingId,
          {
            coupon: code,
            validationType: type,
            zid: this.bookingDetails.zone.locationCode,
            saveRewardCard: saveRewardCard || "",
            isRentalCar: isRentalCar || "",
            rentalCarValidTill: rentalCarValidTill || ""
          }
        );
        await this.getBookingDetails();
        return validateResult;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async openScanValidationCode() {
      this.validateBookingDialog = false;
      this.validateBookingTxt = "";
      this.validateQRCodeDialog = true;
      this.validationCameraStatus = true;
    },
    async onValidateQRCode(decodedString) {
      this.validationCameraStatus = false;
      this.validateQRCodePending = true;
      try {
        let result = await this.validateBooking(decodedString, "coupon");
        if (!result.data.status) {
          this.invalidValidationQRMsg = result?.data?.message
            ? result.data.message
            : "Validation not recognized. Please try again";
          this.invalidValidationQRDialog = true;
          this.validateQRCodePending = false;
          this.postToLoggerAPI({
            phone: this.bookingDetails.user.contact,
            refId: this.bookingId,
            error: this.invalidQRMsg,
            icon: "redeem",
            statusText:
              "Error in validating the session on scanning validation QR - " +
              decodedString,
          });
        } else {
          this.postToLoggerAPI({
            phone: this.bookingDetails.user.contact,
            refId: this.bookingId,
            icon: "redeem",
            statusText:
              "Parker validated the session successfully by scanning validation QR - " +
              decodedString,
          });
          this.validateQRCodePending = false;
          this.validateQRCodeDialog = false;
          this.$store.commit(
            "SET_VALIDATED_MERCHANT",
            result?.data?.merchantName
          );
          const isFreeparkingRedeemed =
            result?.data?.message === "Free Parking redeemed" ? true : false;
          this.$store.commit(
            "SET_REDEEMED_FREE_PARKING_STATUS",
            isFreeparkingRedeemed
          );
          this.$router.replace({ path: "/validatesuccess" });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="less">
.add_licence_plate_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.booking_header {
  position: absolute;
  bottom: 72px;
  // top: 20px; 
  // left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.preauth_header {
  position: relative;
  margin-top: 8px;
  font-size: 18px;
  font-weight: bolder;
  z-index: 10 !important;
}

.time_ago {
  font-size: 22px;
  font-weight: 600;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
}

.time_ago_context {
  font-size: 16px;
  font-weight: 500;
}

.validated_time {
  font-size: 13px;
  font-weight: 500;
}

.booking_divider {
  border-color: white !important;
  margin-top: 12px;
}

.booking_divider_div {
  position: absolute;
  bottom: 60px;
  width: 100%;
}

.booking_content {
  position: absolute !important;
  bottom: 18px !important;
  left: 16px !important;
  font-size: 13px !important;
  font-weight: normal !important;
}

.preauth_content {
  position: relative;
  font-size: 13px !important;
  font-weight: normal !important;
  z-index: 10;

}

.add_credit_card_btn {
  // border-radius: 30px;
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  letter-spacing: normal !important;
  height: 37px !important;
}

.validation-pending {
  position: absolute !important;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.qr_text {
  font-size: 13px;
}

.exit_dialog_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.sms-input input {
  font-size: 36px;
  font-weight: 500;
  color: #44515a !important;
}

.v-text-field--enclosed .v-input__append-inner {
  margin-top: 26px !important;
}

.exit-option-text {
  font-size: 14px;
}

.sms-caption {
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em !important;
}

.qrcode-stream-wrapper video {
  max-height: 300px;
}

.counter-content-position {
  position: relative;
}

.grace-period-image {
  position: absolute !important;
  right: -51px;
  bottom: 5px;
}

.fabIcon {
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
}

#qr-code-full-region__dashboard {
  display: none;
}

#qr-code-full-region>video {
  width: 100% !important;
}

#validation-qr-code>video {
  width: 100% !important;
}

#qr-code-full-region img {
  display: none;
}

.no-upper-case {
  text-transform: unset !important;
}

.time-picker-input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0px;
  font-size: 16px;
  font-family: "Work Sans";
}

.time-picker-input :focus-visible {
  outline-color: #1976d2;
}

.vue__time-picker input.display-time {
  padding: 0px !important;
  border-bottom: 1px solid #767676;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
}

.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #f2555c !important;
}

[data-key="am"] {
  border: 0.2px solid red;
  border-radius: 2px;
  margin-left: 2px;
}

[data-key="pm"] {
  border: 0.2px solid red;
  margin-top: 0.5em;
  margin-left: 2px;
}

.hours .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

.apms .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  margin-left: 1px;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

.hours::-webkit-scrollbar {
  display: none;
}

.minutes::-webkit-scrollbar {
  display: none;
}

.minutes .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

// hr {
//     border: 1px solid #ffbc1f;
// }

#apple-pay-button {
  height: 48px;
  width: 100%;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
}

[data-key="am"] {
  border: 0.2px solid red;
  border-radius: 2px;
  margin-left: 2px;
}

[data-key="pm"] {
  border: 0.2px solid red;
  margin-top: 0.5em;
  margin-left: 2px;
}

.hours .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

.apms .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  margin-left: 1px;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

.hours::-webkit-scrollbar {
  display: none;
}

.minutes::-webkit-scrollbar {
  display: none;
}

.minutes .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

.booking_date {
  font-weight: normal;
  font-size: 13px;
  color: #3d3f3c !important;
}

.booking_date_header {
  color: #3d3f3c !important;
  font-weight: bold;
  font-size: 14px;
}

.total-parking {
  color: #3d3f3c;
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: 0.0892857143em !important;
  text-transform: uppercase !important;
}

.amount-text {
  color: #3d3f3c;
  font-size: 34px;
}

.amount-content-position {
  position: relative;
}

.p-calendar {
  width: 100%;
}

.apply_dialog_btn {
  width: 40%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: normal !important;
  height: 40px !important;
}

.disabled-code {
  opacity: 0.5;
  /* Adjust the opacity value for your desired disabled appearance */
  pointer-events: none;
  /* Disable pointer events on the element */
}

.alert-msg-box {
  background-color: #ececec;
  padding: 15px 20px 10px;
  border-radius: 15px;
  margin: 0px;
  width: 100%;
  height: min-content;
}
</style>
